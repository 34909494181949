<template>
    <g>
        <circle
            v-bind="{
                id : tool.id + '-body',
                cx : tool.pts[0].x, 
                cy : tool.pts[0].y,
                r  : radius
            }" 
        />
    </g>
</template>

// =========================================================================================
// =========================================================================================

<script>
import { tools } from '../../mixins/tools';

// =========================================================================================

export default {
    mixins: [tools],

    data () {
        return {
        }
    },

    computed: {
        radius() {
            var pt1 = this.tool.pts[0],
                pt2 = this.tool.pts[1]; 
            return Math.sqrt(Math.pow((pt1.x - pt2.x), 2) + Math.pow((pt1.y - pt2.y), 2)) 
        }
    },

    methods: {        
    },
}
</script>

// =========================================================================================
// =========================================================================================

<style scoped>
</style>