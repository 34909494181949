<template>
    <g 
        v-show="showTool"
        style="cursor:pointer; pointer-events:all"
        :style="{
            strokeWidth : sizes.strokeW/ui.workArea.zoom.scale,
            stroke      : toolColor,
        }"
    >      
        <g 
            :style="{
                opacity : moving ? 0.5 : (hover || active || denteAtivo) ? 1 : controlInfo.toolOpac/100
            }"
            @mouseover="hover = true"
            @mouseleave="hover = false"
        >

            <g v-for="idx in [0, 1]" :key="tool.id + idx">
                <component 
                    :is="tool.typeStr + 'CMP'"
                    v-bind="{
                        ref       : tool.id,
                        id        : tool.id,
                        toolIndex : toolIndex,  
                        class : (idx == hHoverIndex) ? 'toolHandle' : '',
                        style : {
                            strokeWidth   : ((idx == hHoverIndex) ? sizes.bodyHoverFactor : 1) * sizes.strokeW / ui.workArea.zoom.scale,
                            stroke        : (idx == hHoverIndex) ? 'yellow' : 'inherited',
                            cursor        : 'move',  // path && altKey && hover -> mudar cursor
                            fill          : 'none',
                            border        : (idx == hHoverIndex) ? 20 : 1,
                            pointerEvents : 'stroke'
                        }
                    }"
                ></component>

                <circle             
                    v-for="(pt, hIdx) in tool.pts" :key="hIdx"
                    v-show="(active || hover) && showHandles"
                    @mouseover="hHover = true"
                    @mouseleave="hHover = false"
                    v-bind="{
                        id    : tool.id + '-h[' + hIdx + ']',
                        cx    : pt.x, 
                        cy    : pt.y,
                        r     : (((idx == hHoverIndex) ? sizes.hHoverFactor : (hHover ? 1.5 : 1)) * sizes.handle)/ui.workArea.zoom.scale,
                        class : {
                            toolHandle : (idx == hHoverIndex),
                            toolHandleMov : moving
                        },
                        style : {                             
                            fill   : (idx == hHoverIndex) ? 'yellow' : hColor(tool, hIdx),
                            stroke : (idx == hHoverIndex) ? 'yellow' : 'inherited',
                            strokeWidth : sizes.hStrokeW / ui.workArea.zoom.scale
                        }
                    }" 
                />
            </g>
        </g>        

        <g id="label">
            <text 
                :filter="toolState" 
                v-bind="{
                    ref : label.id,
                    x : label.x,
                    y : label.y,
                    style : {
                        strokeWidth : 1/ui.workArea.zoom.scale,
                        fontSize    : sizes.label.font/ui.workArea.zoom.scale,
                        fill        : toolColor,
                        opacity     : moving 
                                          ? 0.5 
                                          : ((active || hover) && (labelsType == gConsts.label.types.SINGULAR)) 
                                              ? 1 
                                              : controlInfo.labelOpac/100
                    }
                }"
                class="toolLabel"
                dominant-baseline="middle" text-anchor="middle"                
            >
                {{ tool.label + (tool.marked ? '*' : '') }}
            </text>
        </g>
    </g>
</template>

// =========================================================================================
// =========================================================================================

<script>
    import pontoOBJ         from './ponto.vue';
    import retaOBJ          from './reta.vue';
    import retanguloOBJ     from './retangulo.vue';
    import circuloOBJ       from './circulo.vue';
    import retangulo_incOBJ from './retangulo_inc.vue';
    import pathOBJ          from './path.vue';
    import curvaOBJ         from './curva.vue';

    import { tools  } from '../../mixins/tools';
    import { evtBus } from '../../main';

    import { mapGetters, mapState } from 'vuex';
    import { gConsts } from '../../globals/gConsts';
    import { utils } from '../../globals/utils';

    // =========================================================================================

    export default {
        components: {
            'pontoCMP'         : pontoOBJ,
            'retaCMP'          : retaOBJ,
            'retanguloCMP'     : retanguloOBJ,
            'circuloCMP'       : circuloOBJ,
            'retangulo_incCMP' : retangulo_incOBJ,
            'pathCMP'          : pathOBJ,
            'curvaCMP'         : curvaOBJ,
        },

        // -----------------------------------------------------------------

        props: {
            active : { type: Boolean },
            moving : { type: Boolean }
        }, 

        // -----------------------------------------------------------------

        mixins: [tools],

        // -----------------------------------------------------------------

        data () {
            return {
                hHoverIndex : gConsts.tool.handle.HOVER_INDEX,
                sizes : {   
                    label : {
                        dist  : 30,
                        font  : 20,
                        lineW : 1
                    },
                    handle   : 2,
                    strokeW  : 1.5,
                    hStrokeW : 1,
                    hHoverFactor    : 4.5,
                    bodyHoverFactor : 5, 
                },                
                hover  : false,
                hHover : false 
            }
        },

        // -----------------------------------------------------------------

        computed: {
            ...mapState([
                'controlInfo',
                'ui',
                'proj',
                'mov'
            ]),

            // -----------------------------------------------------------------

            ...mapGetters([
                'GET_labels',
            ]),

            // -----------------------------------------------------------------

            showTool() { 
                return this.controlInfo.imgLoaded && (this.tool.labels == this.controlInfo.activeLabels); 
            },

            // -----------------------------------------------------------------

            showHandles () {
                return !this.ui.activeKeys.p;
            },

            // -----------------------------------------------------------------

            toolColor() { 
                var label = this.GET_labels.find(label => this.tool.label == label.label);
                return label ? label.color : this.gConsts.label.colors.DEFAULT;
            },

            // -----------------------------------------------------------------

            toolState() {
                var filter = ''

                if (this.proj.labels[this.tool.labels].type == gConsts.label.types.INPUT) {
                    switch (this.tool.state) {
                        case this.gConsts.tool.states.DOUBT     : filter = 'url(#fDoubt)';     break;
                        case this.gConsts.tool.states.DIVERGENT : filter = 'url(#fDivergent)'; break;
                    }
                }

                return filter;
            },            

            // -----------------------------------------------------------------

            label() {
                var dist, dir, offset, label, labelPos, refPos, p0, p1;

                p0 = this.$Victor.fromObject(this.tool.pts[0]);
                dist = this.sizes.label.dist/this.ui.workArea.zoom.scale;
                if (this.tool.pts.length != 1) {
                    p1 = this.$Victor.fromObject(this.tool.pts[1]);

                    if (this.proj.labelPos == this.gConsts.tool.labelPos.ORIGIN) {
                        labelPos = p0;
                        refPos   = p1;
                    } else {
                        labelPos = p1;
                        refPos   = p0;
                    }

                    dir = labelPos.clone().subtract(refPos).normalize();
                } else {
                    labelPos = p0;
                    dir = this.$Victor(0, -1);
                }

                offset = dir.clone().multiply(this.$Victor(dist, dist));
                label  = labelPos.clone().add(offset);
                label['id'] = this.tool.id + '-lb';

                return label;
            },

            // -----------------------------------------------------------------

            labelsType() {
                return this.proj.labels[this.tool.labels].type;
            },

            // -----------------------------------------------------------------

            denteAtivo() {
                var ad = this.controlInfo.activeDente;

                return ( (ad != -1                                    ) && 
                         (utils.objHasProp(this.tool, 'dente')        ) && 
                         (this.proj.tools[ad].label == this.tool.dente) );
            }
        },

        // -----------------------------------------------------------------

        methods: {
            hColor(tool, ptIndex) {
                var color = 'black';
                if (ptIndex == 0) {
                    color = 'white'
                } else if ((ptIndex >= 2) && (tool.type == gConsts.tool.types.RETANGULO_INC)) {
                    color = 'DeepSkyBlue'
                }

                return color;
            }
        },

        // -----------------------------------------------------------------

        // Lifecycle hooks
        created() {
        },

        // -----------------------------------------------------------------

        mounted() {
            var toolId = this.tool.id;
            evtBus.$emit('evt_toolCreation', toolId, this.$refs[toolId]);
        },

        // -----------------------------------------------------------------

        beforeUpdate() {
//            console.log('bUp!! ' + this.tool.id);
        }
    }
</script>

// =========================================================================================
// =========================================================================================

<style scoped>
    :root {
        --handleOpacity : 0.3;
    }

    .toolHandle {
        opacity : 0;
        transition : opacity 0.2s ease-in;
    }

    .toolHandleMov {
        opacity : 0.1;
    }

    .toolHandle:hover {
        opacity : 0.3;
    }

    .toolLabel {
        font: sans-serif;
        font-size: 47px
    }
</style>