import { gConsts } from './gConsts';
//import { store   } from '../store/store';
import axios from 'axios';
import Victor from 'victor';
import { router } from "../main.js"
// =========================================================================================
// Funcoes globais

export const utils = {
    isInSet(item, set) { return (set.indexOf(item) != -1); },
    objHasProp(obj, prop) { return (prop in obj) },

    // -----------------------------------------------------------------

    pixelToMm(size, dpi) { return size * dpi / 25.4; },

    // -----------------------------------------------------------------

    /* 
        Circle/Ray Intersection (very well explained article)
        ref: https://bluebill.net/circle_ray_intersection.html
    */

    // -----------------------------------------------------------------

    vPerp_vAB(vAB) { 
        return Victor(-vAB.y, vAB.x);
    },

    /* Acha a projecao de P no segmento/vetor vAB:
         Factor < 0 => projP_vAB estah antes do segmento AB (mais prox de pA)
         Factor > 1 => projP_vAB estah depois do segmento AB (mais prox de pB) 
         0 <= Factor <= 1 => projP_vAB estah dentro do segmento AB */
    projP_vAB(pP, pA, vAB) {
        var factor, 
            vAP, proj_vAP_vAB, projP_vAB;

        /* 
            pP - ponto qq
            pA - 1o ponto do segmento AB
            vAB - segmento AB
            vAP - segmento AP
            proj_vAP_vAB - projecão de vAP em vAB 
                => vAB . vAP / vAB . vAB * vAB
            ref: https://en.wikipedia.org/wiki/Vector_projection
                 https://www.youtube.com/watch?v=GnvYEbaSBoY 
        */
        vAP          = pP.clone().subtract(pA);
        factor       = vAB.clone().dot(vAP)/vAB.clone().dot(vAB);
        proj_vAP_vAB = vAB.clone().multiply(Victor(factor, factor));
        projP_vAB    = pA.clone().add(proj_vAP_vAB);

        return { proj : projP_vAB, factor : factor }
    },

    /* 
        Calcula distancia de pP ao segmento de reta vAB
        OBS: se projP_vAB nao estiver sobre o segmento de reta vAB, a distancia = min(distP_A, distP_B)
    */
    distP_vAB(pP, pA, pB) {
        var projP_vAB, dist,
            vAB = pB.clone().subtract(pA);

        projP_vAB = utils.projP_vAB(pP, pA, vAB);
        if (projP_vAB.factor < 0) {
            dist = pP.distance(pA);             // projP_vAB estah antes do segmento AB (mais prox de pA)
        } else if (projP_vAB.factor > 1) {
            dist = pP.distance(pB);             // projP_vAB estah depois do segmento AB (mais prox de pB) 
        } else {
            dist = pP.distance(projP_vAB.proj);  // projP_vAB estah dentro do segmento AB
        }

        return dist;
    },

    // -----------------------------------------------------------------

    findTool(tools, toolId) { return tools.find(tool => (tool.id == toolId)); },
    findToolIndex(tools, toolId) {
        var foundIndex = -1;
        tools.some(function (tool, index) { foundIndex = index; return tool.id == toolId; });
        return foundIndex;
    },
    findToolCMP(vueRoot, toolId) {
        return vueRoot.$children[0].$refs[toolId];
    },

    // -----------------------------------------------------------------

    toolInfo(toolType) {
        var toolInfo = {
            typeStr : '',
            numPts  : 2
        }

        switch (toolType) {
            case gConsts.tool.types.PONTO         : toolInfo.typeStr = 'ponto'        ; break;
            case gConsts.tool.types.RETA          : toolInfo.typeStr = 'reta'         ; break;
            case gConsts.tool.types.RETANGULO     : toolInfo.typeStr = 'retangulo'    ; break;
            case gConsts.tool.types.CIRCULO       : toolInfo.typeStr = 'circulo'      ; break;
            case gConsts.tool.types.RETANGULO_INC : toolInfo.typeStr = 'retangulo_inc'; break;
            case gConsts.tool.types.PATH          : toolInfo.typeStr = 'path'         ; break;
            case gConsts.tool.types.CURVA         : toolInfo.typeStr = 'curva'        ; break;
        }

        switch (toolType) {
            case gConsts.tool.types.PONTO         : toolInfo.numPts =  1; break;
            case gConsts.tool.types.RETANGULO_INC : toolInfo.numPts =  4; break;
            case gConsts.tool.types.PATH          : toolInfo.numPts = -1; break;
            case gConsts.tool.types.CURVA         : toolInfo.numPts = -1; break;
        }

        return toolInfo;
    },

    // -----------------------------------------------------------------

    imgColor(state) {
        var color = '#FFFFFF';

        switch (state) {
            case gConsts.img.states.EMPTY     : color = gConsts.img.colors.EMPTY;     break;
            case gConsts.img.states.PARTIAL   : color = gConsts.img.colors.PARTIAL;   break;
            case gConsts.img.states.DOUBT     : color = gConsts.img.colors.DOUBT;     break;
            case gConsts.img.states.DIVERGENT : color = gConsts.img.colors.DIVERGENT; break;
            case gConsts.img.states.DISCARDED : color = gConsts.img.colors.DISCARDED; break;
            case gConsts.img.states.CONFIRMED : color = gConsts.img.colors.CONFIRMED; break;
        }

        return color
    },

    labelColor(label, usedLabels) {
        return usedLabels.includes(label) ? gConsts.label.colors.USED : gConsts.label.colors.UNUSED;
    },

    // -----------------------------------------------------------------

    localStorage_getItem(key, defVal) {
        var lStorageVal = window.localStorage.getItem(key);

        lStorageVal = (lStorageVal == 'undefined' ? 'null' : lStorageVal);        
        lStorageVal = JSON.parse(lStorageVal);
        return (lStorageVal != null) ? lStorageVal : defVal;
    },

    localStorage_setItem(key, val) {
        localStorage.setItem(key, JSON.stringify(val));
    },

    // -----------------------------------------------------------------

    async saveImage(url, data, ret) {
        let response = ''

        try {
            response = await axios.post(url, data)
            if (response.data.status == 503) {
                localStorage.removeItem('auth')
                //redireciona para o login
                router.push({
                    path: '/login',
                    query: { nextUrl: ret }
                })
                return false;
            } else {
                return true;
            }

        } catch (error) {
            return false;
        }


    }

    // -----------------------------------------------------------------

};
