import Vue from 'vue';
import Vuex from 'vuex';
//import {router} from "../main.js"
import { gConsts } from '../globals/gConsts';
import Victor from 'victor';

import _ from 'lodash';
//import axios from 'axios';

import { utils } from '../globals/utils';
import { undoRedoHistory } from '../globals/undoRedo';

Vue.use(Vuex);

// =========================================================================================

const undoRedoPlugin = (store) => {
    undoRedoHistory.store = store;

    // is called AFTER every mutation
    store.subscribe((mutation, state) => {
        var addState,
            payload = mutation.payload;

            // addState on moveEnd
            addState = ( (mutation.type == 'MUT_movUpdate'   ) &&
                         (utils.objHasProp(payload, 'active')) && 
                         (!payload.active                    ) );

        if (utils.objHasProp(payload, 'addUndoState')) {
            addState = addState || payload.addUndoState;
        }

        if (addState) {
            console.log(mutation.type);
            undoRedoHistory.addState(_.cloneDeep(state));
        }
    });
};

// =========================================================================================

const allTools = {};

export default new Vuex.Store({
    strict: true,

    plugins: [undoRedoPlugin],

    state: {
        proj : {    
            version : -1,
            info : {
                name        : '',
                description : ''
            },            
            labels   : {},
            imgs     : [],
            tools    : [],
            loaded   : false,
            labelPos : gConsts.tool.labelPos.END 
        },

        controlInfo: {
            pxPerMm     : 0,
            leMean      : -1,  // LongoEixo Mean size
            closedPaths : false,
            labelJump   : true,
            labelOpac   : 1,
            toolOpac    : 1,
            dentesROIFactor : 10,

            activeImg   : 0,
            activeLabel : 0,
            activeTool  : null,

            activeLabels : '',
            activeDente  : -1,

            savePending : false,
            imgLoaded   : false,

            serialCounters : {
                tool : 0
            }
        },

        mov: {
            mouseInWa    : false,    
            activeTool   : null, 
            activeToolPt : -1,
            type         : -1,
            active       : false,
            moved        : false,
            dragging     : false,
            mousePos : {
                x : 0,
                y : 0
            }
        },

        ui: {
            workArea : {
                zoom : {
                    scale  : 1,
                    offset : {
                        x : 0,
                        y : 0
                    },
                },    
                
                imgBC : {
                    brightness : 100,
                    contrast   : 100
                },
            },

            activeKeys: {
                p : false,
            },

            toolbar: {
                activeToolType  : gConsts.tool.types.NO_TOOL,
                activeButton    : -1,
                crossHairActive : true,
                showImgOrigin   : false,
            },

            colorTheme : gConsts.ui.colorTheme.themes.LIGHT            
        },
    },

    // =========================================================================================

    getters: {
        GET_imgTools: state => { 
            return state.proj.tools;
        },

        // -----------------------------------------------------------------

        GET_activeTools: state => { 
            var proj = state.proj;
            return proj.tools.filter(tool => (
                tool.labels == state.controlInfo.activeLabels
            ));
        },

        // -----------------------------------------------------------------

        GET_activeAndNavTools: state => { 
            var proj  = state.proj;

            return proj.tools.filter(tool => (                
                ( (tool.labels != ''                                                  ) &&
                  ( (tool.labels == state.controlInfo.activeLabels                  ) ||
                    (proj.labels[tool.labels].type == gConsts.label.types.NAVEGATION) ) )
            ));
        },

        // -----------------------------------------------------------------

        GET_inputTools: state => { 
            var proj  = state.proj;

            return proj.tools.filter(tool => (
                (tool.labels != ''                                         ) &&
                (proj.labels[tool.labels].type == gConsts.label.types.INPUT)
            ));
        },

        // -----------------------------------------------------------------

        GET_leTools: state => { 
            return state.proj.tools.filter(tool => (
                (tool.labels == gConsts.label.navLabels.LE) && 
                (tool.type   == gConsts.tool.types.RETA   ) // pode ter outras marcacoes alem do longoEixo (como supranumerarios)
            ));
        },

        // -----------------------------------------------------------------

        GET_segTools: state => { 
            return state.proj.tools.filter(tool => (
                tool.labels == gConsts.label.navLabels.SEG
            ));
        },

        // -----------------------------------------------------------------

        GET_doubtTools: state => { 
            return state.proj.tools.filter(tool => (
                tool.state == gConsts.tool.states.DOUBT
            ));
        },

        // -----------------------------------------------------------------
        
        GET_emptyImgs: state => { 
            return state.proj.imgs.filter(img => (
                img.state == gConsts.img.states.EMPTY
            ));
        },

        // -----------------------------------------------------------------

        GET_partialImgs: state => { 
            return state.proj.imgs.filter(img => (
                img.state == gConsts.img.states.PARTIAL
            ));
        },

        // -----------------------------------------------------------------

        GET_doubtImgs: state => { 
            return state.proj.imgs.filter(img => (
                img.state == gConsts.img.states.DOUBT
            ));
        },

        // -----------------------------------------------------------------

        GET_divergentImgs: state => { 
            return state.proj.imgs.filter(img => (
                img.state == gConsts.img.states.DIVERGENT
            ));
        },

        // -----------------------------------------------------------------

        GET_discardedImgs: state => { 
            return state.proj.imgs.filter(img => (
                img.state == gConsts.img.states.DISCARDED
            ));
        },

        // -----------------------------------------------------------------

        GET_completedImgs: state => { 
            return state.proj.imgs.filter(img => (
                img.state == gConsts.img.states.CONFIRMED
            ));
        },

        // -----------------------------------------------------------------

        GET_labels: (state, getters) => { 
            var cInfo = state.controlInfo;

            return (cInfo.activeLabels != '') ? state.proj.labels[state.controlInfo.activeLabels].list : [];
        },

        // -----------------------------------------------------------------

        GET_unusedLabels: (state, getters) => { 
            var labels = getters.GET_labels,
                usedLabels = getters.GET_usedLabels;
            
            return labels.filter(label => !usedLabels.includes(label.label));
        },

        // -----------------------------------------------------------------

        GET_usedLabels: (state, getters) => { 
            var usedLabels = [],
                cInfo = state.controlInfo,
                tools = state.proj.tools,
                labels  = state.proj.labels;

            getters.GET_activeTools.forEach(tool => {                
                if ( (labels[cInfo.activeLabels].type == gConsts.label.types.NAVEGATION) ||   // se estamos em uma layer de NAV todas as tools entram na resposta
                     (cInfo.activeDente == -1                                          ) ||   // se estamos em uma layer de input mas nao tem dente ativo, todos os labels entram na resposta
                     (tool.dente == tools[cInfo.activeDente].label                     ) ) {  // se estamos em uma layer de input com dente ativo, somente os labels de tools deste dente entram na resposta
                    usedLabels.push(tool.label);
                }
            });

            return [...new Set(usedLabels)];
        },
    },

    // =========================================================================================

    mutations: {
        MUT_initProject: (state, payload) => {
            var proj = state.proj;

            proj.version          = payload.version;
            proj.folder           = payload.folder;
            proj.info.name        = payload.info.name;
            proj.info.description = payload.info.description;
            proj.labels           = payload.labels;
            proj.loaded           = true;            
        },

        // -----------------------------------------------------------------

        MUT_cInfoUpdate: (state, payload) => {
            var cInfo = state.controlInfo,
                proj  = state.proj;

            if (utils.objHasProp(payload, 'activeDente') && (payload.activeDente === undefined)) {
                console.log('activeDente undefined');
            }

            help.updateObj(cInfo, ['pxPerMm', 
                                   'closedPaths', 'labelJump', 'labelOpac', 'toolOpac', 'dentesROIFactor',
                                   'activeImg', 'activeLabel', 'activeTool', 'activeLabels', 'activeDente', 
                                   'savePending', 'imgLoaded'], payload);

            if (utils.objHasProp(payload, 'closedPaths')) {
                utils.localStorage_setItem('controlInfo.closedPaths', cInfo.closedPaths);
            }

            if (utils.objHasProp(payload, 'labelOpac')) {
                utils.localStorage_setItem('controlInfo.labelOpac', cInfo.labelOpac);
            }

            if (utils.objHasProp(payload, 'toolOpac')) {
                utils.localStorage_setItem('controlInfo.toolOpac', cInfo.toolOpac);
            }

            if (utils.objHasProp(payload, 'dentesROIFactor')) {
                utils.localStorage_setItem('controlInfo.dentesROIFactor', cInfo.dentesROIFactor);
            }

            if (utils.objHasProp(payload, 'activeImg')) {                
                utils.localStorage_setItem('controlInfo.activeImg', cInfo.activeImg);
                proj.tools = allTools[payload.activeImg];
                cInfo.activeDente = -1;

                if (utils.objHasProp(state.proj.labels, gConsts.label.navLabels.LE)) {
                    cInfo.leMean = help.getLeMean(proj.tools);
                }
            }

            if (utils.objHasProp(payload, 'activeLabel')) {
                utils.localStorage_setItem('controlInfo.activeLabel', cInfo.activeLabel);
            }

            if (utils.objHasProp(payload, 'activeLabels')) {
                utils.localStorage_setItem('controlInfo.activeLabels', cInfo.activeLabels);
                cInfo.labelJump = (proj.labels[cInfo.activeLabels].type == gConsts.label.types.SINGULAR);
                cInfo.activeDente = -1;
                cInfo.activeLabel = 0;
            }
        },

        // -----------------------------------------------------------------

        MUT_uiUpdate: (state, payload) => {
            var ui = state.ui;

            if (utils.objHasProp(payload, 'workArea_zoom')) {
                help.updateObj(ui.workArea.zoom, ['scale'], payload.workArea_zoom);
                if (utils.objHasProp(payload.workArea_zoom, 'delta')) {
                    ui.workArea.zoom.offset.x += payload.workArea_zoom.delta.x;
                    ui.workArea.zoom.offset.y += payload.workArea_zoom.delta.y;
                }
            }

            if (utils.objHasProp(payload, 'workArea_imgBC')) {
                if (utils.objHasProp(payload.workArea_imgBC, 'reset')) {
                    ui.workArea.imgBC.contrast   = 100;
                    ui.workArea.imgBC.brightness = 100;
                } else {
                    ui.workArea.imgBC.contrast   += payload.workArea_imgBC.delta.x;
                    ui.workArea.imgBC.brightness += payload.workArea_imgBC.delta.y;
                }
            }

            if (utils.objHasProp(payload, 'activeKeys')) {
                help.updateObj(ui.activeKeys, ['p'], payload.activeKeys);
            }

            if (utils.objHasProp(payload, 'toolbar')) {
                help.updateObj(ui.toolbar, ['activeToolType', 'activeButton', 'crossHairActive', 'showImgOrigin'], payload.toolbar);

                utils.localStorage_setItem('ui.toolbar.activeToolType' , ui.toolbar.activeToolType );
                utils.localStorage_setItem('ui.toolbar.activeButton'   , ui.toolbar.activeButton   );
                utils.localStorage_setItem('ui.toolbar.crossHairActive', ui.toolbar.crossHairActive);
            }
        },

        // -----------------------------------------------------------------

        MUT_movUpdate: (state, payload) => {
            var mov   = state.mov,
                cInfo = state.controlInfo,
                labels = state.proj.labels;

            if (utils.objHasProp(payload, 'type')) {
                if ( (mov.type == gConsts.mov.types.TOOL_CREATION) &&  // se acabou de criar uma tool manualmente (acontece no mouseUp)
                     (payload.type == -1                         ) &&
                     (cInfo.labelJump                            ) ) {
                    cInfo.activeLabel = (cInfo.activeLabel + 1) % labels[cInfo.activeLabels].list.length;
                }
            }

            help.updateObj(mov, ['mouseInWa', 'activeTool', 'activeToolPt', 'type', 'active', 'moved', 'dragging'], payload);
            if (utils.objHasProp(payload, 'mousePos')) {
                mov.mousePos.x = payload.mousePos.x;
                mov.mousePos.y = payload.mousePos.y;
            }

            if ((mov.activeTool != null) && (mov.activeTool != cInfo.activeTool)) {
                cInfo.activeTool = mov.activeTool;
            }

            if (!mov.mouseInWa) {
                cInfo.activeDente = -1;
            }
        },

        // -----------------------------------------------------------------

        MUT_imgsLoad: (state, payload) => {
            state.proj.imgs = payload.imgs;
        },

        // -----------------------------------------------------------------

        MUT_imgUpdate: (state, payload) => {
            var labelIndex, inputTools,
                cInfo = state.controlInfo,
                proj  = state.proj,
                img   = state.proj.imgs[state.controlInfo.activeImg],
                tools = state.proj.tools;                

            if (utils.objHasProp(payload, 'state')) {
                switch (payload.state) {
                    case gConsts.img.states.EMPTY :  
                    case gConsts.img.states.DISCARDED :
                        break;
        
                    case gConsts.img.states.CONFIRMED :
                        inputTools = tools.filter(tool => ( 
                            (tool.labels != ''                                         ) &&
                            (proj.labels[tool.labels].type == gConsts.label.types.INPUT)
                        ));
                        inputTools.forEach(tool => {  // passa todas as inputTools pro state OK
                            tool.state = gConsts.tool.states.OK;
                        });
                        break;
                }
                help.updateObj(img, ['state'], payload);
                cInfo.savePending = true;
            }

            if (utils.objHasProp(payload, 'label')) {
                labelIndex = img.labels.indexOf(payload.label);
                if (labelIndex == -1) {
                    img.labels.push(payload.label);
                } else {
                    img.labels = img.labels.filter(label => (label != payload.label));             
                }
            }
        },

        // -----------------------------------------------------------------

        MUT_toolsLoad: (state, payload) => {
            var serial, typeStr,
                imgs  = state.proj.imgs,                
                cInfo = state.controlInfo;
            
            for (var imgIndex = 0; imgIndex < imgs.length; imgIndex++) {
                allTools[imgIndex] = [];
            }

            serial = cInfo.serialCounters.tool;
            payload.tools.forEach(tool => {
                typeStr = utils.toolInfo(tool.type).typeStr;                

                tool['id'     ] = 'tool' + '_' + typeStr + '[' + serial++ + ']';
                tool['typeStr'] = typeStr;
                allTools[tool.img].push(tool);
            })
            cInfo.serialCounters.tool = serial
        },

        // -----------------------------------------------------------------

        MUT_toolAdd: (state, payload) => {
            var serial, tool, 
                cInfo   = state.controlInfo,
                tools   = state.proj.tools,
                labels  = state.proj.labels,
                typeStr = utils.toolInfo(payload.type).typeStr;
            
            serial = cInfo.serialCounters.tool++;
            tool = {
                id      : 'tool' + '_' + typeStr + '[' + serial + ']',
                type    : payload.type,
                typeStr : typeStr,
                img     : cInfo.activeImg,
                label   : labels[cInfo.activeLabels].list[cInfo.activeLabel].label,
                state   : gConsts.tool.states.OK,
                marked  : false,
                labels  : cInfo.activeLabels,
                dente   : (labels[cInfo.activeLabel] != gConsts.label.types.NAVEGATION) ? cInfo.activeDente : -1,
                pts     : payload.pts
            }
            tools.push(tool);

            cInfo.savePending = true;
            cInfo.activeTool = tool;
        },

        // -----------------------------------------------------------------

        MUT_toolUpdate: (state, payload) => {
            var tool,
                mov   = state.mov,
                cInfo = state.controlInfo;

            if (utils.objHasProp(payload, 'delta') && (payload.delta != null)) {
                tool = mov.activeTool;
                switch (mov.type) {
                    case gConsts.mov.types.TOOL_CREATION :
                    case gConsts.mov.types.HANDLE_EDIT   :
                        tool.pts[mov.activeToolPt].x += payload.delta.x;
                        tool.pts[mov.activeToolPt].y += payload.delta.y;
                        break;
                    case gConsts.mov.types.TOOL_DRAG :
                        tool.pts.forEach(pt => {
                            pt.x += payload.delta.x;
                            pt.y += payload.delta.y;
                        })
                }
            }

            if (utils.objHasProp(payload, 'pts') && (payload.pts != null)) {
                tool = mov.activeTool;
                for (const [key, value] of Object.entries(payload.pts)) {
                    tool.pts[key].x = value.x;
                    tool.pts[key].y = value.y;                    
                }
            }

            if (utils.objHasProp(payload, 'state')) {
                tool = cInfo.activeTool;
                tool.state = payload.state;
            }

            if (utils.objHasProp(payload, 'mark')) {
                tool = cInfo.activeTool;
                tool.marked = !tool.marked;
            }

            if (!cInfo.savePending) { 
                cInfo.savePending = true; 
            }
        },

        // -----------------------------------------------------------------

        MUT_toolAddPts: (state, payload) => {
            var pts,
                addedPts = 0,
                newPts = {},
                mov = state.mov,
                cInfo = state.controlInfo; 

            pts = cInfo.activeTool.pts;
            // se payload nao tem 'newPts'
            if (!utils.objHasProp(payload, 'newPts')) {  
                // estamos criando uma tool e adicionando um novo ponto (duplicando o mov.activeToolPt na mov.activeTool)
                newPts[mov.activeToolPt] = {
                    x : pts[mov.activeToolPt].x,
                    y : pts[mov.activeToolPt].y
                }
            } else {
                newPts = payload.newPts;
            }

            for (const [index, newPt] of Object.entries(newPts)) {
                pts.splice(parseInt(index) + addedPts, 0, newPt); // a cada ponto adicionado, index fica defasado e precisa ser padded
                addedPts++
            }
    
            cInfo.savePending = true;
        },

        // -----------------------------------------------------------------

        MUT_toolDelPts: (state, payload) => {
            var pts,
                cInfo = state.controlInfo; 

            pts = cInfo.activeTool.pts;
            payload.delPts.forEach(delPtIndex => {
                pts.splice(delPtIndex, 1);
            })

            cInfo.savePending = true;
        }, 

        // -----------------------------------------------------------------

        MUT_toolDelete: (state) => {
            var toolIndex,
                tools = state.proj.tools,
                cInfo = state.controlInfo;

            toolIndex = tools.indexOf(cInfo.activeTool);
            if (toolIndex > -1) {
                tools.splice(toolIndex, 1);
            }

            cInfo.savePending = true;
        }
    },

    // =========================================================================================
    
    actions: {
        ACT_initProject: (context, payload) => {
            context.commit('MUT_initProject', payload);
        },        

        // -----------------------------------------------------------------

        ACT_imgsLoad: (context, payload) => {
            context.commit('MUT_imgsLoad', payload);
        },

        // -----------------------------------------------------------------

        ACT_imgUpdate: async (context, payload) => {
            var imgSaveObj, img,
                forceSave = false,
                MUT_imgUpdate_payload = {},
                MUT_cInfoUpdate_payload = {},
                cInfo = context.state.controlInfo,
                proj  = context.state.proj,
                newActiveImg = -1,
                state = -1,
                inc = 0;

            img = proj.imgs[cInfo.activeImg];            
            if (utils.objHasProp(payload, 'control')) {
                switch (payload.control) {
                    case gConsts.img.controls.DISCARD :
                        switch (img.state) {  
                            case gConsts.img.states.DISCARDED : 
                                if (confirm('Este imagem está descartada da instância. Tem certeza que deseja reverter essa ação ?')) {
                                    state = help.getImgState(context, gConsts.img.stateUpdates.IMG_RESET_DISCARDED);
                                }
                                break;

                            default :  // todos os outros states viram DISCARDED  
                                if (confirm('Tem certeza que deseja descartar a imagem corrente da instância ?')) {
                                    state = gConsts.img.states.DISCARDED;
                                }    
                        }
                        break;

                    case gConsts.img.controls.DOUBT :
                        switch (img.state) {  
                            case gConsts.img.states.DOUBT :  // se a img.state estava em DOUBT
                                state = help.getImgState(context, gConsts.img.stateUpdates.IMG_RESET_DOUBT);
                                break;  

                            case gConsts.img.states.DIVERGENT :  // se a img.state estava em DIVERGENT ou DISCARDED
                            case gConsts.img.states.DISCARDED :
                                break;  // state permanece inalterado pois estes states tem precedencia

                            default :  // todos os outros states viram DOUBT 
                                state = gConsts.img.states.DOUBT;  
                        }
                        break;

                    case gConsts.img.controls.BACK :
                        inc = -1;
                        break;

                    case gConsts.img.controls.SKIP :
                        inc = 1;
                        break;

                    case gConsts.img.controls.CONFIRM :
                        state = gConsts.img.states.CONFIRMED;
                        forceSave = true;
                        inc = 1;
                        break;    
                }
                newActiveImg = cInfo.activeImg + inc;
                if (state != -1) {
                    MUT_imgUpdate_payload['state'] = state;
                }
            } else if (utils.objHasProp(payload, 'index')) {
                newActiveImg = payload.index;
            }
            if (utils.objHasProp(payload, 'label')) {
                MUT_imgUpdate_payload['label'] = payload.label;
            }
        
            context.commit('MUT_imgUpdate', MUT_imgUpdate_payload);

            if (cInfo.savePending || forceSave) {
                imgSaveObj = help.imgSaveObj(img, context.getters.GET_imgTools);
                imgSaveObj.token = localStorage.getItem('auth')
//                console.log('Teste await ')
                let response = await utils.saveImage(gConsts.io.server + gConsts.io.reqs.SAVE_IMG + proj.folder, imgSaveObj, proj.folder)

                if(!response){
                    return false;
                }
                MUT_cInfoUpdate_payload['savePending'] = false;
            }

            if ( (newActiveImg != -1              ) &&
                 (newActiveImg != cInfo.activeImg ) && 
                 (newActiveImg != proj.imgs.length) ) {

                MUT_cInfoUpdate_payload['activeImg'  ] = newActiveImg;
                MUT_cInfoUpdate_payload['activeLabel'] = 0;
                MUT_cInfoUpdate_payload['imgLoaded'  ] = false;
                context.commit('MUT_cInfoUpdate', MUT_cInfoUpdate_payload);
            }
        },

        // -----------------------------------------------------------------

        ACT_labelNav: (context, payload) => {
            var cInfo = context.state.controlInfo,
                newActiveLabel = cInfo.activeLabel + payload.inc;            

            if ((newActiveLabel != context.getters.GET_labels.length) && (newActiveLabel != -1)) {
                context.commit('MUT_cInfoUpdate', { activeLabel : newActiveLabel });
            }
        },

        // -----------------------------------------------------------------

        ACT_toolsLoad: (context, payload) => {
            context.commit('MUT_toolsLoad', payload);
        },

        // -----------------------------------------------------------------

        ACT_toolAdd: (context, payload) => {
            context.commit('MUT_toolAdd', payload);
            help.updateImgState(context, gConsts.img.stateUpdates.TOOL_ADD);
        },

        // -----------------------------------------------------------------

        ACT_toolUpdate: (context, payload) => {
            var event = gConsts.img.stateUpdates.TOOL_UPDATE_OTHER;
            
            context.commit('MUT_toolUpdate', payload);
            if (utils.objHasProp(payload, 'state')) {
                event = gConsts.img.stateUpdates.TOOL_UPDATE_STATE;
            }
            help.updateImgState(context, event);
        },

        // -----------------------------------------------------------------

        ACT_toolAddPts: (context, payload) => {
            context.commit('MUT_toolAddPts', payload);
            help.updateImgState(context, gConsts.img.stateUpdates.TOOL_ADD_PTS);
        },

        // -----------------------------------------------------------------

        ACT_toolDelPts: (context, payload) => {
            if (utils.objHasProp(payload, 'delPts') && (payload.delPts.length != 0)) {
                context.commit('MUT_toolDelPts', payload);
                help.updateImgState(context, gConsts.img.stateUpdates.TOOL_DEL_PTS);
            }
        },

        // -----------------------------------------------------------------

        ACT_toolDelete: (context) => {      
            var tools, newActiveTool,
                cInfo = context.state.controlInfo,
                mov   = context.state.mov;

            if ((cInfo.activeTool != null) && (mov.type != gConsts.mov.types.TOOL_CREATION)) {                
                context.commit('MUT_toolDelete', { addUndoState : true });
                help.updateImgState(context, gConsts.img.stateUpdates.TOOL_DELETE);

                tools = context.getters.GET_activeTools;
                newActiveTool = (tools.length > 0) ? tools[tools.length - 1] : null;
                context.commit('MUT_cInfoUpdate', { activeTool : newActiveTool });
            }
        },

        // -----------------------------------------------------------------

        ACT_activeKeysUpdate: (context, payload) => {
            context.commit('MUT_uiUpdate', { activeKeys : payload }); 
        },    

        // -----------------------------------------------------------------

        ACT_toolbarButton: (context, payload) => {
            context.commit('MUT_uiUpdate', { toolbar : payload }); 
        },

        // -----------------------------------------------------------------

        ACT_movUpdate: (context, payload) => {
            context.commit('MUT_movUpdate', payload);            
        },

        // -----------------------------------------------------------------

        ACT_workAreaZoom: (context, payload) => {
            context.commit('MUT_uiUpdate', { workArea_zoom : payload });
        },
        
        // -----------------------------------------------------------------

        ACT_workAreaImgBC: (context, payload) => {
            context.commit('MUT_uiUpdate', { workArea_imgBC : payload });
        },
        
        // -----------------------------------------------------------------

        ACT_cInfoUpdate: (context, payload) => {
            context.commit('MUT_cInfoUpdate', payload );
        },

        // -----------------------------------------------------------------



    }
})


/* 
 * =====================================================================================================
 * ---- HELPER FUNCTIONS -------------------------------------------------------------------------------
 * ===================================================================================================== 
 */

const help = {
    updateObj(obj, props, payload) {
        if (payload === undefined) return;

        props.forEach(prop => {
            if (prop in payload) {
                if (obj[prop] != payload[prop]) {
                    obj[prop] = payload[prop]
                }
            }
        });
    },

    // -----------------------------------------------------------------

    imgSaveObj(img, imgTools) {        
        var toolObj,
            imgObj = {
                fullPathName : img.fullPathName,
                imgOrigin    : img.imgOrigin,
                state        : img.state,
                labels       : img.labels,
                tools : []
            }

        imgTools.forEach(tool => {
            toolObj = {
                type   : tool.type,
                label  : tool.label,
                state  : tool.state,
                marked : tool.marked,
                labels : tool.labels,
                dente  : tool.dente,
                pts    : tool.pts
            }
            imgObj.tools.push(toolObj);
        })

        return imgObj;
    },

    // -----------------------------------------------------------------

    getLeMean(tools) {
        var leCount = 0,
            leSum   = 0;

        tools.forEach(tool => {
            if ((tool.labels == gConsts.label.navLabels.LE) && (tool.type == gConsts.tool.types.RETA)) {
                leSum += Victor.fromObject(tool.pts[0]).subtract(Victor.fromObject(tool.pts[1])).length();
                leCount += 1;
            }            
        });

        return (leCount != 0) ? leSum/leCount : 0;
    },

    // -----------------------------------------------------------------

    updateImgState(context, event) {
        var newState = help.getImgState(context, event);
        if (newState != -1) {
            context.commit('MUT_imgUpdate', { state : newState });
        }
    },

    // -----------------------------------------------------------------

    getImgState(context, event) {
        var i, inputTools,
            img      = context.state.proj.imgs[context.state.controlInfo.activeImg],
            newState = -1;

        switch (event) {
            case gConsts.img.stateUpdates.TOOL_ADD :
                // Se a [img.state == DOUBT || DIVERGENT] adicionar uma tool nao muda nada
                if (utils.isInSet(img.state, [gConsts.img.states.EMPTY, gConsts.img.states.CONFIRMED])) {
                    newState = gConsts.img.states.PARTIAL;
                }
                break;

            case gConsts.img.stateUpdates.TOOL_UPDATE_OTHER : 
            case gConsts.img.stateUpdates.TOOL_ADD_PTS      : 
            case gConsts.img.stateUpdates.TOOL_DEL_PTS      : 
                /* manipulacoes da tool so mudam o img.state se ela jah estava CONFIRMED (lembrando que 
                   [img.state == EMPTY] nao pode ter manipulacoes de tools) pq ela nao tem tools para 
                   serem manipuladas) */
                if (img.state == gConsts.img.states.CONFIRMED) {
                    newState = gConsts.img.states.PARTIAL;
                }
                break;

            // toolDelete, change na tool.state e certas changes do img.state, dispara um reset do img.state
            case gConsts.img.stateUpdates.TOOL_DELETE         : 
            case gConsts.img.stateUpdates.TOOL_UPDATE_STATE   : 
            case gConsts.img.stateUpdates.IMG_RESET_DOUBT     : 
            case gConsts.img.stateUpdates.IMG_RESET_DISCARDED :             
                if ( (img.state == gConsts.img.states.DISCARDED            ) &&  // Se img.state == DISCARDED, soh reseta o state se estivermos revertendo o descarte
                     (event != gConsts.img.stateUpdates.IMG_RESET_DISCARDED) ) { 
                    break; 
                }

                inputTools = context.getters.GET_inputTools;        
                newState = (inputTools.length > 0) ? gConsts.img.states.PARTIAL : gConsts.img.states.EMPTY;
                for (i = 0; i < inputTools.length; i++) {
                    switch (inputTools[i].state) {
                        case gConsts.tool.states.DIVERGENT : newState = gConsts.img.states.DIVERGENT; break;
                        case gConsts.tool.states.DOUBT     : newState = gConsts.img.states.DOUBT    ; break;                
                    }
                    if (newState == gConsts.img.states.DIVERGENT) { break; } // DIVERGENT tem precedencia ... se achou uma jah rege o img.state
                }
                break;        
        }        
        return newState;
    }
}

