var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('g',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTool),expression:"showTool"}],staticStyle:{"cursor":"pointer","pointer-events":"all"},style:({
        strokeWidth : _vm.sizes.strokeW/_vm.ui.workArea.zoom.scale,
        stroke      : _vm.toolColor,
    })},[_c('g',{style:({
            opacity : _vm.moving ? 0.5 : (_vm.hover || _vm.active || _vm.denteAtivo) ? 1 : _vm.controlInfo.toolOpac/100
        }),on:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},_vm._l(([0, 1]),function(idx){return _c('g',{key:_vm.tool.id + idx},[_c(_vm.tool.typeStr + 'CMP',_vm._b({tag:"component"},'component',{
                    ref       : _vm.tool.id,
                    id        : _vm.tool.id,
                    toolIndex : _vm.toolIndex,  
                    class : (idx == _vm.hHoverIndex) ? 'toolHandle' : '',
                    style : {
                        strokeWidth   : ((idx == _vm.hHoverIndex) ? _vm.sizes.bodyHoverFactor : 1) * _vm.sizes.strokeW / _vm.ui.workArea.zoom.scale,
                        stroke        : (idx == _vm.hHoverIndex) ? 'yellow' : 'inherited',
                        cursor        : 'move',  // path && altKey && hover -> mudar cursor
                        fill          : 'none',
                        border        : (idx == _vm.hHoverIndex) ? 20 : 1,
                        pointerEvents : 'stroke'
                    }
                },false)),_vm._l((_vm.tool.pts),function(pt,hIdx){return _c('circle',_vm._b({directives:[{name:"show",rawName:"v-show",value:((_vm.active || _vm.hover) && _vm.showHandles),expression:"(active || hover) && showHandles"}],key:hIdx,on:{"mouseover":function($event){_vm.hHover = true},"mouseleave":function($event){_vm.hHover = false}}},'circle',{
                    id    : _vm.tool.id + '-h[' + hIdx + ']',
                    cx    : pt.x, 
                    cy    : pt.y,
                    r     : (((idx == _vm.hHoverIndex) ? _vm.sizes.hHoverFactor : (_vm.hHover ? 1.5 : 1)) * _vm.sizes.handle)/_vm.ui.workArea.zoom.scale,
                    class : {
                        toolHandle : (idx == _vm.hHoverIndex),
                        toolHandleMov : _vm.moving
                    },
                    style : {                             
                        fill   : (idx == _vm.hHoverIndex) ? 'yellow' : _vm.hColor(_vm.tool, hIdx),
                        stroke : (idx == _vm.hHoverIndex) ? 'yellow' : 'inherited',
                        strokeWidth : _vm.sizes.hStrokeW / _vm.ui.workArea.zoom.scale
                    }
                },false))})],2)}),0),_c('g',{attrs:{"id":"label"}},[_c('text',_vm._b({staticClass:"toolLabel",attrs:{"filter":_vm.toolState,"dominant-baseline":"middle","text-anchor":"middle"}},'text',{
                ref : _vm.label.id,
                x : _vm.label.x,
                y : _vm.label.y,
                style : {
                    strokeWidth : 1/_vm.ui.workArea.zoom.scale,
                    fontSize    : _vm.sizes.label.font/_vm.ui.workArea.zoom.scale,
                    fill        : _vm.toolColor,
                    opacity     : _vm.moving 
                                      ? 0.5 
                                      : ((_vm.active || _vm.hover) && (_vm.labelsType == _vm.gConsts.label.types.SINGULAR)) 
                                          ? 1 
                                          : _vm.controlInfo.labelOpac/100
                }
            },false),[_vm._v(" "+_vm._s(_vm.tool.label + (_vm.tool.marked ? '*' : ''))+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }