<template>
    <g>        
        <circle
            v-bind="{
                id : tool.id + '-body',
                cx : tool.pts[0].x, 
                cy : tool.pts[0].y,
                r  : radiusSize/ui.workArea.zoom.scale,
            }" 
        />
    </g>
</template>

// =========================================================================================
// =========================================================================================

<script>
import { mapState } from 'vuex';
import { tools } from '../../mixins/tools';

// =========================================================================================

export default {
    mixins: [tools],

    data () {
        return {
            radiusSize : 2
        }
    },

    computed: {
        ...mapState([
            'ui',
        ]),
    },

    methods: {        
    },
}
</script>

// =========================================================================================
// =========================================================================================

<style scoped>
</style>