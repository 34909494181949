<template>
    <g>
        <line
            v-bind="{
                id : tool.id + '-body',
                x1 : tool.pts[0].x, 
                y1 : tool.pts[0].y,
                x2 : tool.pts[1].x,   
                y2 : tool.pts[1].y,
            }" 
        />
    </g>
</template>

// =========================================================================================
// =========================================================================================

<script>
import { tools } from '../../mixins/tools';

// =========================================================================================

export default {
    mixins: [tools],

    data () {
        return {
        }
    },

    computed: {
    },

    methods: {
    },
}
</script>

// =========================================================================================
// =========================================================================================

<style scoped>
</style>