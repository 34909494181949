import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import Login from '@/components/Login'
import store from './store/store';

// import vuetify from './plugins/vuetify';

import Victor from 'victor';
import axios from 'axios';

Object.defineProperty(Vue.prototype, '$Victor', { value: Victor });
Object.defineProperty(Vue.prototype, '$axios' , { value: axios  });

export const evtBus = new Vue();

Vue.config.productionTip = false;
Vue.use(VueRouter);

export const router = new VueRouter({
    routes : [
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                guest: true
            }
            
        },
        {
            path : '/:folder',
            name : 'home',
            component : App,
            meta:{
                requiresAuth: true
            }
            
        } 
    ],
    mode: 'history'
})

router.beforeEach((to,from,next)=>{ 

    if(to.matched.some(record => record.meta.requiresAuth)){
        
        if(localStorage.getItem('auth') == null){
            router.push({
                path: '/login',
                query: { nextUrl: to.fullPath }
            })
        }else{
            next()
        }
    }else{
        next()
    }
})

const app = new Vue({
  store,
  router,
//  vuetify,
  render: h => h(App)
}).$mount('#app')

store.$app = app;
