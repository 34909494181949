import { mapActions, mapState } from 'vuex';
import { gConsts } from '../globals/gConsts';
    
export const tools = {
    props: {
        toolIndex: Number
    },

    // =========================================================================================

    data () {
        return {
            gConsts : gConsts,
        }
    },

    // =========================================================================================

    computed: {
        ...mapState([ 
            'proj',
        ]),

        // -----------------------------------------------------------------

        tool() { 
            return this.proj.tools[this.toolIndex];
        },
    },

    // =========================================================================================

    methods: {
        mouseDown(evt, pM, toolPt) { return false; },
        mouseMove(evt, pM)         { return false; },
        mouseUp(evt, pM)           { return false; },
    }
}