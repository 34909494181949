<template>
    <div id="app">
        <router-view v-if="showRouter"/>
        <div id="divDPI" style="position:absolute; width:1in"></div>

        <div 
            id="divLoadingPanel" 
            v-show="proj.loaded ? !controlInfo.imgLoaded : true"
            style="
                z-index:100; 
                opacity:0.7; 
                background:#FFFFFF; 
                position:fixed; 
                width:100%; 
                height:100%
            "
        > 
            <table style="position:absolute; left:50%; top:50%; margin-left:-200px; margin-top:-70px;">
                <tr>
                    <td align="center">
                        <img 
                            :src="require('./assets/loading.gif')" 
                            alt="Carregando ..." 
                            height="140" 
                            width="400"
                        />
                    </td>
                </tr>
                <tr>
                    <td align="center">
                        <strong><span id="divLoadingPanelMsg">Por favor aguarde, <br>Carregando dados ...</span></strong><br>
                    </td>
                </tr>
            </table>
        </div>

        <div 
            id="div_grid_container"
            @mousemove="mouseTarget = $event.target">
            <div id="header" :style="{ background : colorTheme.header }">
                <div id="div_appTitle">
                    <span style="font-size: 18px; font-weight: bold">RM Annotations</span>
                </div>
            </div>

            <div 
                id="div_toolBar" 
                :style="{ background : colorTheme.toolbar }"
            >
                <!-- input 
                    type="file" 
                    id="inputLoadProj" 
                    accept=".json" 
                    title="Open project file"
                    ref="projFile"
                    @change="loadProjFromFile"
                / -->

                <div style="display: inline-block; margin-top:5px; margin-right: 10px;">
                    <buttonCMP 
                        id="toolBar_pan" 
                        hint="Arrasta a imagem (apertar a tecla CTRL para ativar a qualquer momento)"
                        :img="require('./assets/pan.gif')"
                        :active="(ui.toolbar.activeButton == gConsts.ui.toolbar.buttons.WA_PAN)"
                        :style="{ width: '40px', height: '35px' }"
                        @click="toolbarButton(gConsts.ui.toolbar.buttons.WA_PAN, gConsts.tool.types.NO_TOOL)"
                    ></buttonCMP>

                    <buttonCMP 
                        id="toolBar_imgBC" 
                        hint="Altera o brilho e contraste da imagem (atalho: SHIFT / click com botão direito para resetar)"
                        :img="require('./assets/bc.svg')"
                        :active="(ui.toolbar.activeButton == gConsts.ui.toolbar.buttons.IMG_BC)"
                        :style="{ width: '40px', height: '35px' }"
                        @click="toolbarButton(gConsts.ui.toolbar.buttons.IMG_BC, gConsts.tool.types.NO_TOOL)"
                        @contextmenu.prevent.native="ACT_workAreaImgBC({ reset : true })"
                    ></buttonCMP>

                    <buttonCMP 
                        v-show="proj.loaded && (proj.imgs[controlInfo.activeImg].imgOrigin != null)"
                        id="toolBar_imgOrigin" 
                        hint="Mostra a imagem origem da imagem corrente (se houver alguma)"
                        :img="require('./assets/imgOrigin.gif')"
                        :active="ui.toolbar.showImgOrigin"
                        :style="{ width: '40px', height: '35px' }"
                        @click="ACT_toolbarButton({ showImgOrigin : !ui.toolbar.showImgOrigin })"
                    ></buttonCMP>

                    <buttonCMP 
                        id="toolBar_fitToScreen" 
                        hint="Ajusta a imagem ao espaço disponível"
                        :img="require('./assets/fittoscreen.gif')"
                        :style="{ width: '40px', height: '35px' }"
                        @click="$refs.medidor.fitToWorkArea()"
                    ></buttonCMP>

                    <buttonCMP 
                        id="toolBar_save" 
                        hint="Salva anotações da imagem corrente (dispara automáticamente quando muda a imagem)"
                        :img="require('./assets/save.gif')"
                        :style="{ width: '40px', height: '35px' }"
                        @click="ACT_imgUpdate({})"
                    ></buttonCMP>

                    <buttonCMP 
                        id="toolBar_crossHair" 
                        hint="Liga/desliga a cruz/alvo d do mouse"
                        :img="require('./assets/crossHair.gif')"
                        :active="(ui.toolbar.crossHairActive)"
                        :style="{ width: '40px', height: '35px' }"
                        @click="ACT_toolbarButton({ crossHairActive : !ui.toolbar.crossHairActive })"
                    ></buttonCMP>
                </div>

                <div style="display: inline-block; margin-top:5px; margin-right: 50px;">
                    <buttonCMP 
                        id="toolBar_undo" 
                        :img="require('./assets/Voltar.png')"
                        :active="undoActive"
                        :style="{ width: '40px', height: '35px'}"
                        @click="undoRedoHistory.undo();"
                    ></buttonCMP>
                    <buttonCMP 
                        id="toolBar_redo" 
                        :img="require('./assets/Avancar.png')"
                        :active="redoActive"
                        :style="{ width: '40px', height: '35px'}"
                        @click="undoRedoHistory.redo();"
                    ></buttonCMP>
                </div>

                <div style="display: inline-block; margin-top:5px; margin-right: 50px;">
                    <buttonCMP 
                        id="toolBar_ponto" 
                        :img="require('./assets/btPonto.gif')"
                        :active="(ui.toolbar.activeButton == gConsts.ui.toolbar.buttons.PONTO)"
                        :style="{ width: '40px', height: '35px' }"
                        @click="toolbarButton(gConsts.ui.toolbar.buttons.PONTO, gConsts.tool.types.PONTO)"
                    ></buttonCMP>

                    <buttonCMP 
                        id="toolBar_reta" 
                        :img="require('./assets/btReta.gif')"
                        :active="(ui.toolbar.activeButton == gConsts.ui.toolbar.buttons.RETA)"
                        :style="{ width: '40px', height: '35px' }"
                        @click="toolbarButton(gConsts.ui.toolbar.buttons.RETA, gConsts.tool.types.RETA)"
                    ></buttonCMP>

                    <buttonCMP 
                        id="toolBar_retangulo" 
                        :img="require('./assets/btRetangulo.gif')"
                        :active="ui.toolbar.activeButton == gConsts.ui.toolbar.buttons.RETANGULO"
                        :style="{ width: '40px', height: '35px' }"
                        @click="toolbarButton(gConsts.ui.toolbar.buttons.RETANGULO, gConsts.tool.types.RETANGULO)"
                    ></buttonCMP>

                    <buttonCMP 
                        id="toolBar_circulo" 
                        :img="require('./assets/btCirculo.gif')"                        
                        :active="ui.toolbar.activeButton == gConsts.ui.toolbar.buttons.CIRCULO"
                        :style="{ width: '40px', height: '35px' }"
                        @click="toolbarButton(gConsts.ui.toolbar.buttons.CIRCULO, gConsts.tool.types.CIRCULO)"
                    ></buttonCMP>

                    <buttonCMP 
                        id="toolBar_retangulo_inc" 
                        hint="Desenha 1o a linha superior inclinada e depois define a altura"
                        :img="require('./assets/btRet_inc.gif')"
                        :active="ui.toolbar.activeButton == gConsts.ui.toolbar.buttons.RETANGULO_INC"
                        :style="{ width: '40px', height: '35px' }"
                        @click="toolbarButton(gConsts.ui.toolbar.buttons.RETANGULO_INC, gConsts.tool.types.RETANGULO_INC)"
                    ></buttonCMP>

                    <buttonCMP 
                        id="toolBar_path" 
                        hint="Desenha um path de de múltiplos pontos ... clique do botão direito para terminar"
                        :img="require('./assets/btPath.gif')"                        
                        :active="ui.toolbar.activeButton == gConsts.ui.toolbar.buttons.PATH"
                        :style="{ width: '40px', height: '35px' }"
                        @click="toolbarButton(gConsts.ui.toolbar.buttons.PATH, gConsts.tool.types.PATH)"
                    ></buttonCMP>

                    <buttonCMP 
                        id="toolBar_curva" 
                        hint="Desenha uma curva de múltiplos pontos ... clique do botão direito para terminar"
                        :img="require('./assets/btCurva.gif')"                        
                        :active="ui.toolbar.activeButton == gConsts.ui.toolbar.buttons.CURVA"
                        :style="{ width: '40px', height: '35px' }"
                        @click="toolbarButton(gConsts.ui.toolbar.buttons.CURVA, gConsts.tool.types.CURVA)"
                    ></buttonCMP>

                    <buttonCMP 
                        color="#fa7575"
                        id="toolBar_delete" 
                        hint="Apaga a anotação corrente"
                        :img="require('./assets/btDelTool.gif')"
                        :style="{ 
                            width: '40px', 
                            height: '35px',
                        }"
                        @click="toolDelete"     
                    ></buttonCMP>
                </div>

                <div style="display: inline-block; margin-top:5px;">
                    <buttonCMP 
                        id="toolBar_imgLabel" 
                        :img="require('./assets/label.gif')"                        
                        :active="ui.toolbar.activeButton == gConsts.ui.toolbar.buttons.IMG_LABEL"
                        :style="{ width: '40px', height: '35px' }"
                        @click="toolbarButton(gConsts.ui.toolbar.buttons.IMG_LABEL, gConsts.tool.types.IMG_LABEL)"
                    ></buttonCMP>
                </div>

                <!-- target : {{ mouseTarget }} -->
            </div>

            <div id="div_imgList_container" :style="{ background : colorTheme.panels }">
                <div id="div_imgList_header">
                    <table width="100%">
                        <tr>
                            <td colspan="2">
                                <span 
                                    style="text-align:center; font-size: 18px; font-weight: bold"
                                    :style="{ color : colorTheme.titles }"
                                >
                                    Images
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td class="imgList_body_text">Total</td>
                            <td>{{ proj.imgs.length }}</td>
                        </tr>
                        <tr :style="{ background : gConsts.img.colors.EMPTY }">
                            <td class="imgList_body_text">Vazias</td>
                            <td>{{ GET_emptyImgs.length }}</td>
                        </tr>
                        <tr>
                            <td colspan="2" class="imgList_body_text">
                                Incompletas
                            </td>
                        </tr>
                        <tr :style="{ background : gConsts.img.colors.PARTIAL }">
                            <td class="imgList_body_text_subItem">
                                Parciais
                            </td>
                            <td>{{ GET_partialImgs.length }}</td>
                        </tr>
                        <tr :style="{ background : gConsts.img.colors.DOUBT }">
                            <td class="imgList_body_text_subItem">
                                Dúvidas
                            </td>
                            <td>{{ GET_doubtImgs.length }}</td>
                        </tr>
                        <tr :style="{ background : gConsts.img.colors.DIVERGENT }">
                            <td class="imgList_body_text_subItem">
                                Divergências
                            </td>
                            <td>{{ GET_divergentImgs.length }}</td>
                        </tr>
                        <tr :style="{ background : gConsts.img.colors.DISCARDED }">
                            <td class="imgList_body_text_subItem">
                                Descartadas
                            </td>
                            <td>{{ GET_discardedImgs.length }}</td>
                        </tr>
                        <tr :style="{ background : gConsts.img.colors.CONFIRMED }">
                            <td class="imgList_body_text">Completas</td>
                            <td>{{ GET_completedImgs.length }}</td>
                        </tr>
                    </table>
                </div>

                <div id="div_imgList_data">
                    <ul>
                        <li v-for="(img, index) in proj.imgs" 
                            :key="img + '-' + index"
                            @click="ACT_imgUpdate({ index : index })"
                            @contextmenu.prevent=""
                            v-bind="{
                                id    : 'li_img[' + index + ']',
                                class : {selLi : (controlInfo.activeImg == index)},
                                style : { background : utils.imgColor(img.state) }
                            }"
                            >{{ '[' + index + '] ' + img.fullPathName }}
                        </li>
                    </ul>
                </div>

                <div 
                    id="div_imgList_controls"
                    style="padding:0px"
                >
                    <buttonCMP 
                        id="imgControls_doubt" 
                        hint="Marca a imagem corrente como [Dúvida] e pula para a próxima da lista"
                        :img="require('./assets/btDoubt.gif')"
                        :style="imgControlsStyle"
                        @click="imgControls(gConsts.img.controls.DOUBT)"
                    ></buttonCMP>

                    <buttonCMP 
                        id="imgControls_discard" 
                        hint="Descarta a imagem corrente e pula para a próxima da lista"
                        :img="require('./assets/btDiscard.gif')"
                        :style="imgControlsStyle"
                        @click="imgControls(gConsts.img.controls.DISCARD)"
                    ></buttonCMP>

                    <buttonCMP 
                        id="imgControls_back" 
                        hint="Volta para a imagem anterior"
                        :img="require('./assets/btBack.svg')"
                        :style="imgControlsStyle"
                        @click="imgControls(gConsts.img.controls.BACK)"
                    ></buttonCMP>

                    <buttonCMP 
                        id="imgControls_skip" 
                        hint="Marca a imagem corrente como [Parcial] (se houver anotações) e pula para a próxima da lista"
                        :img="require('./assets/btSkip.svg')"
                        :style="imgControlsStyle"
                        @click="imgControls(gConsts.img.controls.SKIP)"
                    ></buttonCMP>

                    <buttonCMP
                        :color="gConsts.img.colors.CONFIRMED"
                        hint="Marca a imagem corrente como [Completa] e pula para a próxima da lista"
                        id="imgControls_confirm" 
                        :img="require('./assets/confirmBW.gif')"
                        :style="imgControlsStyle"
                        @click="imgControls(gConsts.img.controls.CONFIRM)"
                    ></buttonCMP>
                </div>

            </div>

            <div 
                id="div_workArea" 
                ref="workArea"
                class="workArea"
                @contextmenu.prevent=""
                :style="{ 
                    background : colorTheme.workArea,
                    cursor : ui.toolbar.toolActive ? 'grab' : 'pointer',
                    position : 'relative'
                }"
            >
                <div id="div_imgOrigin"
                    v-if="ui.toolbar.showImgOrigin"
                    style="position:absolute"
                >
                    <img id="img_imgOrigin"
                        ref="img_imgOrigin"
                        :style="{
                            width  : '100%',
                            height : '100%',
                            zIndex : 10
                        }"
                        :src="imgOriginSrc"
                        @load="imgOriginLoaded"
                    >      

                    <svg
                        id="svg_imgOrigin"                        
                        ref="svg_imgOrigin" 
                        style="left:0px; top:0px; width:100%; height:100%; pointer-events:none; position:absolute;"
                    >
                        <g 
                            :transform="'scale(' + imgOrigin.scale + ')'"
                        >
                            <path 
                                v-bind="{    
                                    style : 'opacity:' + controlInfo.toolOpac/100,
                                    d : imgOriginPath_d
                                }"
                                stroke="red"
                                stroke-width="5"
                                fill="none"
                            />
                        </g>
                    </svg>
                </div>

                <div 
                    id="div_workAreaZoom"
                    class="workAreaZoom"
                    style="transform-origin: 0px 0px;background: lightblue;"
                    :style="workAreaZoomStyle"                    
                >
                    <medidorCMP 
                        id="medidor" 
                        ref="medidor"
                        style="width:0px; height:0px"
                        :class="{ discardedImg : isDiscardedimg }"
                    >
                        <div id="div_mainImg" slot="mainImg">
                            <img 
                                :style="{
                                    filter: 'brightness(' + ui.workArea.imgBC.brightness + '%) contrast(' + ui.workArea.imgBC.contrast + '%)',
                                }"
                                id="mainImg"
                                ref="mainImg"
                                v-show="proj.loaded ? controlInfo.imgLoaded : false"
                                :src="activeImgSrc"
                                @load="activeImgLoaded"
                                :class="{
                                    mainImg : true,
                                    mainImg_border : ui.toolbar.showImgOrigin
                                }"
                            >      
                        </div>

                        <div 
                            id="div_tools" 
                            ref="div_tools"                             
                            slot="tools"
                            style="left:0; top:0; position:absolute;"
                        >
                            <svg
                                id="svg_tools"
                                ref="svg_tools"                            
                                style="left:0px, top:0px; pointer-events:none; position:absolute; z-index:20; overflow:visible;"

                            >

                                <defs>
                                    <filter id="fDoubt" x="-250%" y="-250%" width="500%" height="500%">
                                        <feComponentTransfer>
                                            <feFuncR type="table" tableValues="0.85"></feFuncR>
                                            <feFuncG type="table" tableValues="0.22"></feFuncG>
                                            <feFuncB type="table" tableValues="1"></feFuncB>
                                        </feComponentTransfer>
                                        <feMorphology operator="dilate" :radius="toolState.fRadius/ui.workArea.zoom.scale"/>
                                        <feGaussianBlur result="out" :stdDeviation="toolState.fStdDev/ui.workArea.zoom.scale"/>
                                        <feBlend in="SourceGraphic" in2="out" mode="normal"/>
                                    </filter>
                                    <filter id="fDivergent" x="-250%" y="-250%" width="500%" height="500%">
                                        <feComponentTransfer>
                                            <feFuncR type="table" tableValues="1"></feFuncR>
                                            <feFuncG type="table" tableValues="0.22"></feFuncG>
                                            <feFuncB type="table" tableValues="0.22"></feFuncB>
                                        </feComponentTransfer>
                                        <feMorphology operator="dilate" :radius="toolState.fRadius/ui.workArea.zoom.scale"/>
                                        <feGaussianBlur result="out" :stdDeviation="toolState.fStdDev/ui.workArea.zoom.scale"/>
                                        <feBlend in="SourceGraphic" in2="out" mode="normal"/>
                                    </filter>

                                </defs>

                                <!-- transition name="denteROI" -->
                                <denteROICMP 
                                    key="uei"
                                    v-if="controlInfo.activeDente != -1"
                                ></denteROICMP>
                                <!-- /transition -->
                                                                
                                <circle id="toolPushCircle"
                                    v-show="utils.isInSet(mov.type, [gConsts.mov.types.HANDLES_PUSH, gConsts.mov.types.HANDLES_DEL])"
                                    :style="{
                                        strokeWidth : 2/ui.workArea.zoom.scale,
                                        stroke      : mov.type == gConsts.mov.types.HANDLES_PUSH ? 'blue' : 'red',
                                        fill        : mov.type == gConsts.mov.types.HANDLES_PUSH ? 'blue' : 'red',
                                        opacity     : 0.3
                                    }"

                                    v-bind="{
                                        cx : (mov.mousePos.x - ui.workArea.zoom.offset.x) / ui.workArea.zoom.scale, 
                                        cy : (mov.mousePos.y - ui.workArea.zoom.offset.y) / ui.workArea.zoom.scale,
                                        r  : gConsts.tool.sizes.HANDLES_PUSH_DIST/ui.workArea.zoom.scale
                                    }" 
                                />

                                <toolCMP v-for="tool in GET_activeAndNavTools" 
                                    :key="tool.id"
                                    v-bind="{
                                        ref : tool.id,
                                        id  : tool.id, 
                                        toolIndex : utils.findToolIndex(proj.tools, tool.id), 
                                        moving : (controlInfo.activeTool == tool) && mov.active && (!utils.isInSet(mov.type, [gConsts.mov.types.HANDLES_PUSH, gConsts.mov.types.HANDLES_DEL])),
                                        active : (controlInfo.activeTool == tool)
                                    }"
                                ></toolCMP>

                                <crossHairCMP
                                    v-if="ui.toolbar.crossHairActive"
                                ></crossHairCMP>
                            </svg>
                        </div>

                        <!-- svg
                            id="svg" 
                            style="pointer-events: none; position: absolute; z-index:6; overflow: visible;" 
                            :style="svgStyle"
                            slot="svg"
                        >
                        </svg -->                    
                    </medidorCMP>
                </div>  <!-- div_workAreaZoom -->
            </div>  <!-- div_workArea -->

            <div 
                id="div_labelList_container"
                :style="{ background : colorTheme.panels }"
            >
                <div id="div_labelList_header">
                    <table width="100%">
                        <tr>
                            <td colspan="2">
                                <span 
                                    style="text-align:center; font-size: 18px; font-weight: bold"
                                    :style="{ color : colorTheme.titles }"
                                >
                                    Labels
                                </span>
                            </td>
                        </tr>

                        <tr>
                            <td class="imgList_body_text">Total</td>
                            <td>{{ GET_labels.length }}</td>
                        </tr>

                        <tr :style="{ background : gConsts.label.colors.UNUSED }">
                            <td class="imgList_body_text">Não usados</td>
                            <td>{{ GET_unusedLabels.length }}</td>
                        </tr>

                        <tr :style="{ background : gConsts.label.colors.USED }">
                            <td class="imgList_body_text">Usados</td>
                            <td>{{ GET_usedLabels.length }}</td>
                        </tr>

                        <tr>
                            <td class="imgList_body_text" colspan="2">
                                <hr>
                            </td>
                        </tr>

                        <tr>
                            <td class="imgList_body_text" colspan="2">
                                <span>Layer</span>
                                <select v-model="vModels.selLabels" 
                                    style="float: right; width:90px"
                                    @change="labelsChange">
                                    <option v-for="option in Object.keys(proj.labels)" 
                                        :key="option"
                                        :value="option">
                                        {{option}}
                                    </option>
                                </select>
                            </td>
                        </tr>

                        <tr>
                            <td class="imgList_body_text" colspan="2">
                                Paths fechadas: 
                                <input v-model="vModels.closedPaths"
                                    type="checkbox" 
                                    style="float: right;"
                                    @change="closedPathsChange"
                                >
                            </td>
                        </tr>
                        <tr>
                            <td class="imgList_body_text" colspan="2">
                                Pular q/ marcar: 
                                <input v-model="vModels.labelJump"
                                    type="checkbox" checked
                                    style="float: right;"
                                    @change="labelJumpChange"
                                >
                            </td>
                        </tr>
                        <tr>
                            <td class="imgList_body_text" colspan="2">
                                Label Opac.: 
                                <input v-model="vModels.labelOpac"
                                    type="range" min="1" max="100" value="100" class="slider"
                                    style="float: right; width:80px"
                                    @change="labelOpacChange"
                                >
                            </td>
                        </tr>

                        <tr>
                            <td class="imgList_body_text" colspan="2">
                                Tool Opac.: 
                                <input v-model="vModels.toolOpac"
                                    type="range" min="1" max="100" value="100" class="slider"
                                    style="float: right; width:80px"
                                    @change="toolOpacChange"
                                >
                            </td>
                        </tr>

                        <tr>
                            <td class="imgList_body_text" colspan="2">
                                Margem (dt): 
                                <input v-model="vModels.dentesROIFactor"
                                    type="range" min="5" max="15" value="10" class="slider"
                                    style="float: right; width:80px"
                                    @change="dentesROIFactorChange"
                                >
                            </td>
                        </tr>

                    </table>

                </div>
                <div id="div_labelList_data">
                    <ul>
                        <li v-for="(label, index) in GET_labels" 
                            :key="label.label + '-' + index"
                            @click="labelClick(label.label, index)"
                            @dblclick="ACT_imgUpdate({ label : label.label })"
                            v-bind="{
                                id    : 'li_label[' + index + ']',
                                class : {
                                    selLi    : (controlInfo.activeLabel == index),
                                    imgLabel : isImgLabel(label.label)
                                },
                                style : { 
                                    background : utils.labelColor(label.label, GET_usedLabels)
                                }
                            }"
                            >
                                <table width="100%">
                                    <tr style="display:grid;grid-template-columns:100px auto">
                                        <td>
                                            <div 
                                                :style="{
                                                    display    : 'inline-block',
                                                    width      : '14px',
                                                    height     : '14px',
                                                    background : label.color
                                                }">
                                            </div>
                                            {{ label.label + (isImgLabel(label.label) ? ' **' : '') }}
                                        </td>
                                        <td>
                                            {{ (label.desc != '' ? ' [' + label.desc + ']' : '') }}
                                        </td>
                                    </tr>
                                </table>
                        </li>
                    </ul>
                </div>
            </div>

            <div 
                id="footer" 
                style="font-size: 18px;"
                :style="{ 
                    background : colorTheme.header,
                    color : colorTheme.titles                         
                }"
            >
                <span style="font-weight: bold">Project: </span>
                    {{ proj.info.name }} / 
                <span style="font-weight: bold">Pasta: </span>
                    {{ proj.folder }}

                <!-- span style="color:blue">[Imagem origem]</span>
                <a id"="imgOrigin"
                    v-bind="{
                        href :  
                :href="https://www.w3schools.com">Visit W3Schools</a -->

            </div>

        </div>
    </div>  <!-- app -->
</template>

// =========================================================================================
// =========================================================================================

<script>
    import medidorOBJ   from './components/medidor.vue';
    import buttonOBJ    from './components/button.vue';
    import toolOBJ      from './components/tools/tool.vue';
    import crossHairOBJ from './components/crossHair.vue';
    import denteROIOBJ  from './components/denteROI.vue';

    import { mapActions, mapGetters, mapState } from 'vuex';
    import { gConsts } from './globals/gConsts';
    import { utils   } from './globals/utils';
    import { helper  } from './mixins/helper';
    import {demoProj} from './assets/demoProj';
    import { undoRedoHistory } from './globals/undoRedo';

    //import {router} from "./main.js"
    export default {
        components: {
            'medidorCMP'   : medidorOBJ,
            'buttonCMP'    : buttonOBJ,
            'toolCMP'      : toolOBJ,
            'crossHairCMP' : crossHairOBJ,
            'denteROICMP'  : denteROIOBJ
        },

        // -----------------------------------------------------------------

        mixins: [helper],

        // -----------------------------------------------------------------

        data: () => ({
            mouseTarget : '',
            firstImgLoad : true,
            gConsts : gConsts,
            utils   : utils,
            demoProj : demoProj,
            showRouter: false,
            localExec : false,
            appStarted : false,
            undoRedoHistory : undoRedoHistory,
            toolState : {
                fRadius : 7,
                fStdDev : 5
            },
            imgOrigin : {
//                loaded : false,
                scale  : 1
            },
            vModels : {
                selLabels   : '',
                closedPaths : false,
                labelJump   : true,
                labelOpac   : 100,
                toolOpac    : 100,
                dentesROIFactor : 10
            }
        }),

        // -----------------------------------------------------------------

        computed: {
            ...mapState([
                'proj',
                'ui',
                'controlInfo',
                'mov'
            ]),

            // -----------------------------------------------------------------

            ...mapGetters([
                'GET_activeAndNavTools',                
                'GET_emptyImgs',
                'GET_partialImgs',
                'GET_doubtImgs',
                'GET_divergentImgs',
                'GET_discardedImgs',
                'GET_completedImgs',
                'GET_labels',
                'GET_unusedLabels',
                'GET_usedLabels'
            ]),

            // -----------------------------------------------------------------

            undoActive() {
                return (this.undoRedoHistory.currentIndex > 0);  // nao quero dar undo ateh o estado inicial default da store
            },

            // -----------------------------------------------------------------

            redoActive() {
                return (undoRedoHistory.currentIndex < undoRedoHistory.history.length - 1);
            },

            // -----------------------------------------------------------------

            workAreaZoomStyle() {
                var zoom = this.ui.workArea.zoom;

                return {
                    transform : 'translate(' + zoom.offset.x + 'px, ' + zoom.offset.y + 'px) ' +  
                                'scale(' + zoom.scale + ')'
//                    transition : this.appStarted ? 'transform ' + zoom.animate + 's ease-out' : 'none',
                }
            },

            // -----------------------------------------------------------------

            imgControlsStyle() {
                return { 
                    width  : '25px', 
                    height : '25px',
                    padding : '3px'
                }
            },

            // -----------------------------------------------------------------

            activeImg() {
                return this.proj.imgs[this.controlInfo.activeImg]
            },

            // -----------------------------------------------------------------

            activeImgSrc() {                
                return this.proj.loaded ? 
                    this.gConsts.io.server + 
                    this.gConsts.io.reqs.LOAD_IMG + 
                    this.activeImg.fullPathName + '?auth='+ localStorage.getItem('auth')
                    : '';
            },

            // -----------------------------------------------------------------

            imgOriginSrc() {
                return this.proj.loaded && (this.proj.imgs[this.controlInfo.activeImg].imgOrigin != null) ?
                    this.gConsts.io.server + 
                    this.gConsts.io.reqs.LOAD_IMG + 
                    this.activeImg.imgOrigin.fullPathName + '?auth='+ localStorage.getItem('auth')
                    : '';
            },

            // -----------------------------------------------------------------

            imgOriginPath_d() {
                var idx, pts,
                    dStr = '';
                
                if (this.proj.loaded && (this.proj.imgs[this.controlInfo.activeImg].imgOrigin != null)) {
                    pts = this.proj.imgs[this.controlInfo.activeImg].imgOrigin.coords;
                    dStr = 'M ' + pts[0].x + ' ' + pts[0].y;            
                    for (idx = 1; idx < pts.length; idx++) {
                        dStr += ' L ' + pts[idx].x + ' ' + pts[idx].y
                    }
                    dStr += ' Z';
                }

                return dStr;
            },

            // -----------------------------------------------------------------

            isDiscardedimg() {                
                return this.controlInfo.imgLoaded && (this.proj.imgs[this.controlInfo.activeImg].state == gConsts.img.states.DISCARDED);
            }
        },

        // -----------------------------------------------------------------

        watch: {
            '$route': function(){
                window.location.reload()
                this.showRouter = !this.$route.params.folder
            },
            'controlInfo.activeImg': function() {
                this.$nextTick(function() {
                    // faz o scroll pra imagem selecionado caso necessario
                    document.getElementById('li_img[' + this.controlInfo.activeImg + ']').scrollIntoViewIfNeeded();
                });                
            },

            'controlInfo.activeLabel': function() {
                this.$nextTick(function() {
                    // faz o scroll pra imagem selecionado caso necessario
                    document.getElementById('li_label[' + this.controlInfo.activeLabel + ']').scrollIntoViewIfNeeded();
                });                
            }
        },

        // -----------------------------------------------------------------

        methods: {
            ...mapActions([
                'ACT_initProject',                
                'ACT_imgsLoad',
                'ACT_imgUpdate',
                'ACT_toolsLoad',
                'ACT_toolbarButton',
                'ACT_workAreaImgBC',
                'ACT_toolDelete',
                'ACT_cInfoUpdate'
            ]),

            // -----------------------------------------------------------------

            loadProjFromFile(evt) {
                const filename = evt.target.files[0];
                const fReader = new FileReader();
                fReader.onload = (evt) => {
                    var content = evt.target.result;
                    this.loadProjData(JSON.parse(content));
                };
                fReader.onerror = evt => {
                    console.error(evt);
                };
                fReader.readAsText(filename, "UTF-8");
            },

            // -----------------------------------------------------------------

            loadProjData(projData, folder) {
                var imgs   = [],
                    tools  = [];

                if (this.localExec) {  // UEI : mudar qdo JSON do servidor for atualizado
                    // projData['version'] = 3;
                    // projData.info    = this.demoProj.info;
                    // projData.labels  = this.demoProj.labels;
                    projData = this.demoProj;
                }

                if (!utils.objHasProp(projData, 'version') || projData.version < 3) {
                    alert('Este projData.JSON está desatualizado. Favor atualizar para versão atual [3]');
                    return;
                }

                this.ACT_initProject({                    
                    version : projData.version,
                    folder  : folder,
                    labels  : projData.labels,
                    info : {                        
                        name        : projData.info.name,
                        description : projData.info.description,
                    },
                })          

                projData.imgs.forEach(img => {                    
                    imgs.push({
                        fullPathName : img.fullPathName,                        
                        imgOrigin    : utils.objHasProp(img, 'imgOrigin' ) ? img.imgOrigin : null,
                        state        : utils.objHasProp(img, 'state'     ) ? img.state     : gConsts.img.states.EMPTY,
                        labels       : utils.objHasProp(img, 'labels'    ) ? img.labels    : []
                    });

                    if (utils.objHasProp(img, 'tools')) {
//                        if (img.tools.length > 0) {
//                            console.log(img.fullPathName);
//                        }
                        img.tools.forEach(tool => {
                            tools.push({
                                type   : tool.type,
                                img    : (imgs.length - 1),
                                label  : tool.label,
                                state  : utils.objHasProp(tool, 'state' ) ? tool.state  : this.gConsts.tool.states.OK, 
                                marked : utils.objHasProp(tool, 'marked') ? tool.marked : false, 
                                labels : utils.objHasProp(tool, 'labels') ? tool.labels : '',
                                dente  : utils.objHasProp(tool, 'dente' ) ? tool.dente  : -1,
                                pts    : tool.pts
                            });
                        }, this);
                    }
                }, this);

                this.ACT_imgsLoad(  { imgs : imgs } );
                this.ACT_toolsLoad( { tools : tools } );
            },

            // -----------------------------------------------------------------

            activeImgLoaded: function() {
                this.ACT_cInfoUpdate({ imgLoaded : true });
                if (this.firstImgLoad) {
                    this.$nextTick(function() {
                        this.$refs.medidor.fitToWorkArea();
                        this.firstImgLoad = false;
                        this.appStarted = true;
                        this.undoRedoHistory.reset();  // no 1o carregamento eu soh posso chamar o reset depois do fitToWA
                    });
                } else {
                    this.undoRedoHistory.reset();  // reseto o undo/redo history qdo mudo de imagem
                }
            },

            // -----------------------------------------------------------------

            imgOriginLoaded: function() {
                this.imgOrigin.scale = this.getImgOriginScale();
            },

            // -----------------------------------------------------------------

            getImgOriginScale: function() {
                var imgOriginElem = this.$refs['img_imgOrigin'];
                return imgOriginElem.offsetWidth / imgOriginElem.naturalWidth;
            },

            // -----------------------------------------------------------------

            toolbarButton: function(button, toolType) {                
                this.ACT_toolbarButton({ 
                    activeButton   : button,
                    activeToolType : toolType
                });
            },

            // -----------------------------------------------------------------

            labelsChange(evt) {
                this.ACT_cInfoUpdate({ activeLabels : evt.target.value });
                this.vModels.labelJump = this.controlInfo.labelJump;
                this.undoRedoHistory.reset();
            },

            // -----------------------------------------------------------------

            closedPathsChange(evt) {
                this.ACT_cInfoUpdate({ closedPaths : evt.target.checked });
            },

            // -----------------------------------------------------------------
            labelJumpChange(evt) {
                this.ACT_cInfoUpdate({ labelJump : evt.target.checked });
            },

            // -----------------------------------------------------------------

            labelOpacChange(evt) {
                this.ACT_cInfoUpdate({ labelOpac : evt.target.value });
            },

            // -----------------------------------------------------------------

            toolOpacChange(evt) {
                this.ACT_cInfoUpdate({ toolOpac : evt.target.value });
            },

            // -----------------------------------------------------------------

            dentesROIFactorChange(evt) {
                this.ACT_cInfoUpdate({ dentesROIFactor : evt.target.value });
            },

            // -----------------------------------------------------------------

            toolDelete: function() {
                this.ACT_toolDelete();
            },

            // -----------------------------------------------------------------

            labelClick(label, index) {
                this.ACT_cInfoUpdate({ activeLabel : index })
                if (this.ui.toolbar.activeToolType == gConsts.tool.types.IMG_LABEL) {
                    this.ACT_imgUpdate({ label : label });
                }
            },

            // -----------------------------------------------------------------

            isImgLabel(label) {
                return (this.activeImg.labels.indexOf(label) != -1)
            },

            // -----------------------------------------------------------------

            imgControls: function(control) {
                this.ACT_imgUpdate({ control : control });
            },

            // -----------------------------------------------------------------

            loadConfig: function(confTypes) {
                var activeImg   = 0,
                    activeLabel = 0;

                // Carrega configuracoes globais (independente do projeto)
                if (this.utils.isInSet(gConsts.init.confTypes.GLOBAL, confTypes)) {
                    this.ACT_toolbarButton({
                        activeToolType  : this.utils.localStorage_getItem('ui.toolbar.activeToolType' , gConsts.tool.types.NO_TOOL       ),
                        activeButton    : this.utils.localStorage_getItem('ui.toolbar.activeButton'   , gConsts.ui.toolbar.buttons.WA_PAN),
                        crossHairActive : this.utils.localStorage_getItem('ui.toolbar.crossHairActive', true                                  )
                    });
                }

                // Carrega configuracoes do projeto 
                if (this.utils.isInSet(this.gConsts.init.confTypes.PROJ, confTypes)) {
                    var projFolder = this.utils.localStorage_getItem('proj.folder', '');

                    // Configuracoes especificas do projeto somente sao carregadas se estamos abrindo o mesmo projeto
                    if (projFolder == this.proj.folder) {
                        this.vModels.selLabels       = this.utils.localStorage_getItem('controlInfo.activeLabels', Object.keys(this.proj.labels)[0]);
                        this.vModels.closedPaths     = this.utils.localStorage_getItem('controlInfo.closedPaths', false);
                        this.vModels.labelOpac       = this.utils.localStorage_getItem('controlInfo.labelOpac', 100);
                        this.vModels.toolOpac        = this.utils.localStorage_getItem('controlInfo.toolOpac', 100);
                        this.vModels.dentesROIFactor = this.utils.localStorage_getItem('controlInfo.dentesROIFactor', 10);
                        activeImg   = this.utils.localStorage_getItem('controlInfo.activeImg'  , 0);
                        activeLabel = this.utils.localStorage_getItem('controlInfo.activeLabel', 0);
                    } else {
                        this.utils.localStorage_setItem('proj.folder', this.proj.folder);
                        this.vModels.selLabels       = Object.keys(this.proj.labels)[0];
                        this.vModels.closedPaths     = false;
                        this.vModels.labelOpac       = 100;
                        this.vModels.toolOpac        = 100;
                        this.vModels.dentesROIFactor = 10;
                    }
                    this.ACT_cInfoUpdate({
                        activeImg       : activeImg,
                        activeLabel     : activeLabel,
                        activeLabels    : this.vModels.selLabels,
                        closedPaths     : this.vModels.closedPaths,
                        labelOpac       : this.vModels.labelOpac,
                        toolOpac        : this.vModels.toolOpac,
                        dentesROIFactor : this.vModels.dentesROIFactor
                    })
                    this.vModels.labelJump = this.controlInfo.labelJump;
                }
            }
        },

        // =========================================================================================
        // Lifecycle hooks

        created() {
//            this.loadProjData(demoProj)
        },

        // -----------------------------------------------------------------

        mounted() {
            var folder = this.$route.params.folder;

            // console.log(this.$refs);
            //console.log(this.$route.params); 
            if(!this.$route.params.folder){
                this.showRouter = true
            }else{
                this.showRouter = false
            }
            let token = localStorage.getItem('auth')
            this.loadConfig([this.gConsts.init.confTypes.GLOBAL]);
            
            if(!token)return;
            this.$axios
                .post(this.gConsts.io.server + this.gConsts.io.reqs.LOAD_PROJ + folder, {token: token})
                .then(response => {
//                    console.log(response.data.status)
                    if (response.data.status == 503){
                        if (this.$route.params.folder){
                            this.$router.push({
                                path: '/login',
                                query: { nextUrl: this.$route.params.folder}
                            })

                        }
                    } else if (response.status != 200) {
                        alert('Erro ao carregar o projeto: [' + response.status + '] ' + response.statusText);
                    } else {
                        this.loadProjData(response.data, folder)
                        this.loadConfig([this.gConsts.init.confTypes.PROJ]);
                    }
                })
                .catch(error => console.log('axios [getImgs] error: ' + error));
        }
    }
</script>

// =========================================================================================
// =========================================================================================

<style>

html, body {
    margin: 0;
    height: 100%;
    padding: 0px;
    font-family: Verdana, Sans-Serif
}

#app {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
/*    left: 0px;
    height: 0px;
    padding: 0px;
    position: absolute; */
}

UL {
    list-style: none;
    padding-left:0px;
    margin-top: 0px;
    position: absolute;
    height: 100%;
    min-width: 100%;
    top: 0px;
    left: 0px;
    overflow: visible;
}

LI {
    cursor: pointer;
    padding-left:10px;    
    text-align: left;
    user-select : none;
    font-family: Verdana, Sans-Serif;
    overflow: visible;
    white-space: nowrap;
}

.selLi {    
    outline: 1px solid black;
    filter: brightness(75%);
}

.imgLabel {
    display: list-item;
    list-style: circle;
    font-weight: bold;
}

.discardedImg {
    opacity : 0.2;
}

/*
Essa classe/CSS fez a imagem redimensionar sem independente do zoom o que mudou a magnificacao 
sem atualizar os params da imagem e fez as coordenadadas das tools desincronizarem 
com a imagem ... estou deixando aqui somente para lembrar desse comportamento interessante.
.mainImg {
    max-height:100%; 
    max-width:100%;
}
*/

#div_appTitle {
    display: flex;
    background-color: rgb(0,87,130); 
    width: 190px; 
    height: 100%; 
    margin-left:auto; 
    margin-right:0px;
    padding:auto
}

#div_appTitle > span {
    color: white; 
    margin:auto; 
    font-size:17px;
}

.buttonImg {
    margin:auto;
    width:25px;
    height:25px;
}

#div_grid_container {
    text-align: center;
    width: 100%;
    height: 100%;
    display : grid;
    grid-template-rows : 30px 50px auto 30px;
    grid-template-columns : 200px auto 200px;
    grid-template-areas :
        "header  header   header"
        "toolBar toolBar  toolBar"
        "imgList workArea labelList"
        "footer  footer   footer";
    grid-gap: 0px;
    padding: 0px;
}

#header {
    grid-area: header;
    box-shadow: 0px 2px 5px -2px #000000;
    z-index: 40;
}

#div_toolBar {
    background: #eeeeee;
    grid-area: toolBar;
    z-index: 10;
    box-shadow: 0px 2px 5px -2px #000000;
}

#div_imgList_container {
    background: #f3f3f3;
    grid-area: imgList;
    display: grid;
    grid-template-columns : auto;
    grid-template-rows    : 210px auto 45px;
    grid-template-areas :
        "imgList_header"
        "imgList_data"
        "imgList_controls";
    grid-row-gap: 5px;
    z-index: 1;
    box-shadow: 1px 0px 5px -2px #000000;
}

#div_imgList_header {
    grid-area: imgList_header;
    box-shadow: 0px 2px 5px -2px #000000;
    z-index: 40;
}

.imgList_body_text {
    text-align : left;
}

.imgList_body_text_subItem {
    text-align   : left;
    padding-left : 20px;
}

#div_imgList_data {
    grid-area: imgList_data;
    box-shadow: 0px 1px 4px 0px rgba(150, 150, 150, 0.5);
    z-index: 40;
    position: relative;
    overflow: auto;
}

#div_imgList_controls {
    grid-area: imgList_controls;
    box-shadow: 0px -2px 5px -2px #000000;
}


#div_workArea {
    background: #f5f5f5;
    grid-area: workArea;
    height: 100%;
    overflow: hidden;
    box-shadow: 3px 0px 4px -2px rgba(150, 150, 150, 0.7);    
}

.mainImg_border {
    box-shadow: 0px 0px 5px 3px rgba(70, 30, 30, 1);    
}

#div_labelList_container {
    background: #f3f3f3;
    grid-area: labelList;
    display: grid;
    grid-template-columns : auto;
    grid-template-rows : 260px auto;
    grid-template-areas :
        "labelList_header"
        "labelList_data";
    row-gap: 5px;
    z-index: 1;
    box-shadow: -1px 0px 5px -2px #000000;
}

#div_labelList_header {
    grid-area: labelList_header;
    box-shadow: 0px 2px 5px -2px #000000;
    z-index: 40;
}

#div_labelList_data {
    grid-area: labelList_data;
    box-shadow: 0px 1px 4px 0px rgba(150, 150, 150, 0.5);
    z-index: 40;
    position: relative;
    overflow: auto; 
}

#footer {
    background: #dddddd;
    grid-area: footer;
    box-shadow: 0px -3px 5px -2px #000000;
    z-index: 40;
}

/*
.denteROI-enter-active,
.denteROI-leave-active {
  transition: opacity 0.5s ease;
}

.denteROI-enter-from,
.denteROI-leave-to {
  opacity: 0;
}
*/

</style>