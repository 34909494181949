var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('g',{directives:[{name:"show",rawName:"v-show",value:(_vm.mov.mouseInWa),expression:"mov.mouseInWa"}],attrs:{"stroke-width":1/_vm.ui.workArea.zoom.scale,"stroke":"red","stroke-dasharray":_vm.dasharrayStr}},[_c('line',_vm._b({},'line',{
            id : 'crossHair-horizLine',
            x1 : _vm.mousePos.x - _vm.size,
            y1 : _vm.mousePos.y,
            x2 : _vm.mousePos.x + _vm.size,   
            y2 : _vm.mousePos.y,
        },false)),_c('line',_vm._b({},'line',{
            id : 'crossHair-vertLine',
            x1 : _vm.mousePos.x,
            y1 : _vm.mousePos.y - _vm.size,
            x2 : _vm.mousePos.x,   
            y2 : _vm.mousePos.y + _vm.size,
        },false))])}
var staticRenderFns = []

export { render, staticRenderFns }