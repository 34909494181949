<template>
    <g>
        <line
            v-for="(pt, index) in tool.pts" :key="index"
            v-bind="{
                id : tool.id + '-body',
                x1 : tool.pts[index].x, 
                y1 : tool.pts[index].y,
                x2 : tool.pts[(index+1) % tool.pts.length].x,   
                y2 : tool.pts[(index+1) % tool.pts.length].y,
            }" 
        />
    </g>
</template>

// =========================================================================================
// =========================================================================================

<script>
import { mapActions, mapState } from 'vuex';
import { tools   } from '../../mixins/tools';
import { utils   } from '../../globals/utils';

// =========================================================================================

export default {
    mixins: [tools],

    data () {
        return {
        }
    },

    computed: {
        ...mapState([
            'mov',
        ]),
    },

    methods: {
        ...mapActions([
            'ACT_toolAddPt',
            'ACT_toolUpdate',
            'ACT_toolAddPts'
        ]),

        mouseMove(evt, pM) {
            var isEdit, isCreationPt2,
                vADLen, 
                pA, pB, pC, pD,
                vAB, vPerp_vAB, vBC,
                tool, toolPt,
                totalToolPtsReached = false,
                pts = null,
                handled = false;

            tool   = this.mov.activeTool;
            toolPt = this.mov.activeToolPt;

            isEdit        = this.mov.type == this.gConsts.mov.types.HANDLE_EDIT;
            isCreationPt2 = (this.mov.type == this.gConsts.mov.types.TOOL_CREATION) && (toolPt == 2);
            if (isEdit || isCreationPt2) {
                pA = this.$Victor.fromObject(tool.pts[0]);
                pB = this.$Victor.fromObject(tool.pts[1]);

                // Calc pC e pD baseado na projecao de vBM em vPerp_vAB
                if (utils.isInSet(toolPt, [2, 3])) {
                    totalToolPtsReached = (utils.toolInfo(tool.type).numPts == tool.pts.length)
                    if ((this.mov.type == this.gConsts.mov.types.TOOL_CREATION) && (!totalToolPtsReached)) {
                        this.ACT_toolAddPts({});
                    }

                    vAB       = pB.clone().subtract(pA);
                    vPerp_vAB = utils.vPerp_vAB(vAB);
                    pC        = utils.projP_vAB(pM, pB, vPerp_vAB).proj;  // acha a projM em vPerp_vAB = pC
                    vBC       = pC.clone().subtract(pB);
                    pD        = pA.clone().add(vBC);

                    pts = {
                        2 : pC.toObject(),
                        3 : pD.toObject()
                    }

                // Calc pA || pB baseado no mouseMove e pC e pD baseado no comprimento anterior de vAD 
                } else if (utils.isInSet(toolPt, [0, 1])) {            
                    pD = this.$Victor.fromObject(tool.pts[3]);
                    pC = this.$Victor.fromObject(tool.pts[2]);

                    vADLen = pD.clone().subtract(pA).length();  // acha o tamanho do lado perp antes do move
                    switch (toolPt) {                           // atualiza pA ou pB para pM (pos do mouse)
                        case 0 : pA.copy(pM); break;
                        case 1 : pB.copy(pM); break;
                    }
                    vAB       = pB.clone().subtract(pA);
                    vPerp_vAB = new this.$Victor(-vAB.y, vAB.x).normalize().multiply(new this.$Victor(vADLen, vADLen));

                    pC = pB.clone().add(vPerp_vAB);
                    pD.copy(pA.clone().add(vPerp_vAB));

                    pts = {                        
                        2 : pC.toObject(),
                        3 : pD.toObject()
                    }      
                    switch (toolPt) {
                        case 0 : pts[0] = pA.toObject(); break;
                        case 1 : pts[1] = pB.toObject(); break;
                    }
                }

                if (pts != null) {
                    this.ACT_toolUpdate({ pts : pts });
                    handled = true;
                }
            }

            return handled;
        }
    },

    // =========================================================================================
    // Lifecycle hooks

    created() {
    },

}
</script>

// =========================================================================================
// =========================================================================================

<style scoped>
</style>