import { gConsts } from '../globals/gConsts';


export const demoProj = {
    //    "version" : 1,
    //    "version" : 2,  // adicionei as listas de labels para representarem layers diferentes no RMAnnotations
    "version" : 3,  /* Inclusões e alterações dos seguintes campos:
                        (adicao)      version (versao do JSON)
                        (alteracao)   labels.type => labels.jump [gConsts.label.jumps.AUTOMATIC (0) / MANUAL (1)]
                        (adicao)      labels.type [gConsts.label.types.NAVEGATION (0)/ INPUT (1)]
                        (adicao)      imgs[].state [gConsts.img.states.EMPTY (0) / PARTIAL (1) / CONFIRMED (2) / DOUBT (3) / DIVERGENT (4)] (o campo já existe, apenas adicionei estados pra imagem)
                        (alteracao)   imgs[].fName => imgs.fullPathName (fullPathName da imagem a ser anotada)
                        (adicao)      imgs[].imgOrigin { fullPathName, coords { x, y, width, height } } (fullPathName e coords da imagem origem)
                        (adicao)      imgs[].tools[].state [gConsts.tool.states.OK (0) / DOUBT (1) / DIVERGENT (2)] (estados da tool)
                        (remocao)     imgs[].tools[].marked (não é usado e o state vai substituir fazendo melhor)
                    */

	"info" : {
		"name" : "Marcação de Anomalias",
		"description": "Marcação do longo eixo dos dentes na radiografia panorâmica",
    },

    // boa ref pra escolher as cores => https://www.w3schools.com/colors/colors_groups.asp
    "labels": {
        "Dentes" : {
            jump : gConsts.label.jumps.AUTOMATIC,
            type : gConsts.label.types.NAVEGATION,
            list : [
                { "label" : "18", "desc" : "", "color" : "red"    },
                { "label" : "17", "desc" : "", "color" : "red"    },
                { "label" : "16", "desc" : "", "color" : "red"    },
                { "label" : "15", "desc" : "", "color" : "blue"   },
                { "label" : "14", "desc" : "", "color" : "blue"   },
                { "label" : "13", "desc" : "", "color" : "yellow" },
                { "label" : "12", "desc" : "", "color" : "green"  },
                { "label" : "11", "desc" : "", "color" : "green"  },
                { "label" : "21", "desc" : "", "color" : "green"  },
                { "label" : "22", "desc" : "", "color" : "green"  },
                { "label" : "23", "desc" : "", "color" : "yellow" },
                { "label" : "24", "desc" : "", "color" : "blue"   },
                { "label" : "25", "desc" : "", "color" : "blue"   },
                { "label" : "26", "desc" : "", "color" : "red"    },
                { "label" : "27", "desc" : "", "color" : "red"    },
                { "label" : "28", "desc" : "", "color" : "red"    },

                { "label" : "38", "desc" : "", "color" : "red"    },
                { "label" : "37", "desc" : "", "color" : "red"    },
                { "label" : "36", "desc" : "", "color" : "red"    },
                { "label" : "35", "desc" : "", "color" : "blue"   },
                { "label" : "34", "desc" : "", "color" : "blue"   },
                { "label" : "33", "desc" : "", "color" : "yellow" },
                { "label" : "32", "desc" : "", "color" : "green"  },
                { "label" : "31", "desc" : "", "color" : "green"  },
                { "label" : "41", "desc" : "", "color" : "green"  },
                { "label" : "42", "desc" : "", "color" : "green"  },
                { "label" : "43", "desc" : "", "color" : "yellow" },
                { "label" : "44", "desc" : "", "color" : "blue"   },
                { "label" : "45", "desc" : "", "color" : "blue"   },
                { "label" : "46", "desc" : "", "color" : "red"    },
                { "label" : "47", "desc" : "", "color" : "red"    },
                { "label" : "48", "desc" : "", "color" : "red"    },

                { "label" : "SP", "desc" : "Supranumerário", "color" : "white" }
            ],
        },

		"Segmentacao": {
            jump : gConsts.label.jumps.AUTOMATIC,
            type : gConsts.label.types.NAVEGATION,
			"list": [
                { "label" : "18", "desc" : "", "color" : "red"    },
                { "label" : "17", "desc" : "", "color" : "red"    },
                { "label" : "16", "desc" : "", "color" : "red"    },
                { "label" : "15", "desc" : "", "color" : "blue"   },
                { "label" : "14", "desc" : "", "color" : "blue"   },
                { "label" : "13", "desc" : "", "color" : "yellow" },
                { "label" : "12", "desc" : "", "color" : "green"  },
                { "label" : "11", "desc" : "", "color" : "green"  },
                { "label" : "21", "desc" : "", "color" : "green"  },
                { "label" : "22", "desc" : "", "color" : "green"  },
                { "label" : "23", "desc" : "", "color" : "yellow" },
                { "label" : "24", "desc" : "", "color" : "blue"   },
                { "label" : "25", "desc" : "", "color" : "blue"   },
                { "label" : "26", "desc" : "", "color" : "red"    },
                { "label" : "27", "desc" : "", "color" : "red"    },
                { "label" : "28", "desc" : "", "color" : "red"    },

                { "label" : "38", "desc" : "", "color" : "red"    },
                { "label" : "37", "desc" : "", "color" : "red"    },
                { "label" : "36", "desc" : "", "color" : "red"    },
                { "label" : "35", "desc" : "", "color" : "blue"   },
                { "label" : "34", "desc" : "", "color" : "blue"   },
                { "label" : "33", "desc" : "", "color" : "yellow" },
                { "label" : "32", "desc" : "", "color" : "green"  },
                { "label" : "31", "desc" : "", "color" : "green"  },
                { "label" : "41", "desc" : "", "color" : "green"  },
                { "label" : "42", "desc" : "", "color" : "green"  },
                { "label" : "43", "desc" : "", "color" : "yellow" },
                { "label" : "44", "desc" : "", "color" : "blue"   },
                { "label" : "45", "desc" : "", "color" : "blue"   },
                { "label" : "46", "desc" : "", "color" : "red"    },
                { "label" : "47", "desc" : "", "color" : "red"    },
                { "label" : "48", "desc" : "", "color" : "red"    },

                { "label" : "SP", "desc" : "Supranumerário", "color" : "white" }
			]
        },

        "Anomalias" : {
            jump : gConsts.label.jumps.MANUAL,
            type : gConsts.label.types.INPUT,
            list : [
                { "label" : "Calc"  , "desc" : "Cálculo"                              , "color" : "Pink"         },
                { "label" : "NodP"  , "desc" : "Nódulo pulpar"                        , "color" : "HotPink"      },
                { "label" : "Car"   , "desc" : "Imagem radiolúcida coronária - cárie" , "color" : "fuchsia"      },
                { "label" : "ACor"  , "desc" : "Ausência coronária - total ou parcial", "color" : "MediumOrchid" },
                { "label" : "POsv"  , "desc" : "Perda óssea severa ou vertical"       , "color" : "Indigo"       },
                { "label" : "IRPer" , "desc" : "Imagem radiolúcida periapical - lesão", "color" : "red"          },
                { "label" : "Frat"  , "desc" : "Fratura - coronária ou radicular"     , "color" : "DarkRed"      },
                { "label" : "RRad"  , "desc" : "Resto radicular"                      , "color" : "orange"       },
                { "label" : "DtInc" , "desc" : "Dente incluso"                        , "color" : "yellow"       },
                { "label" : "HCtse" , "desc" : "Hipercementose"                       , "color" : "green"        },
                { "label" : "DsInc" , "desc" : "Desgaste incisal"                     , "color" : "Lime"         },
                { "label" : "Giro"  , "desc" : "Giroversão"                           , "color" : "Teal"         },
                { "label" : "RbRad" , "desc" : "Reabsorção radicular"                 , "color" : "cyan"         },
                { "label" : "Dilac" , "desc" : "Dilaceração"                          , "color" : "blue"         },
                { "label" : "Patg"  , "desc" : "Patologia"                            , "color" : "RoyalBlue"    },
                { "label" : "Outs"  , "desc" : "Outros achados importantes"           , "color" : "SaddleBrown"  }
            ] 
        }
    },

	"imgs": [
        {
            "fullPathName":"testversion3/104__2533-62955.jpg",
            "imgOrigin" : {
                "fullPathName":"testversion3/104__2533-62955.jpg",
                "type"   : 0, // 0: poligono, 1: longo eixo
                "coords" : [
                    { "x": 300, "y": 200 },
                    { "x": 350, "y": 250 }
                ]
            },
            "state":2,
            "labels":[],
            "tools":[
                {
                    "type":0,
                    "state":0,
                    "label":"17",
                    "labels":"Dentes",
                    "pts":[
                        {"x":966.836536082474,"y":796.6285979381443},
                        {"x":960.4629278350516,"y":564.6292577319585}
                    ]
                },
                {"type":0,"label":"16","marked":false,"labels":"Dentes","pts":[{"x":1068.8142680412368,"y":795.3538762886595},{"x":1040.7703917525769,"y":563.3545360824746}]},
                {"type":0,"label":"13","marked":true,"labels":"Dentes","pts":[{"x":1251.0994639175256,"y":720.1452989690721},{"x":1247.275298969072,"y":528.9370515463913}]},
                {"type":0,"label":"22","marked":true,"labels":"Dentes","pts":[{"x":1535.362391752577,"y":730.3430721649484},{"x":1523.889896907216,"y":567.1787010309279}]},
                {"type":0,"label":"26","marked":false,"labels":"Dentes","pts":[{"x":1795.4056082474224,"y":808.101092783505},{"x":1813.2517113402052,"y":574.8270309278347}]},
                {"type":0,"label":"27","marked":false,"labels":"Dentes","pts":[{"x":1894.8338969072163,"y":792.8044329896907},{"x":1920.3283298969066,"y":573.5523092783509}]},
                {"type":0,"label":"37","marked":false,"labels":"Dentes","pts":[{"x":1896.1086185567008,"y":834.8702474226803},{"x":2056.7235463917527,"y":1001.8587835051545}]},
                {"type":0,"label":"35","marked":false,"labels":"Dentes","pts":[{"x":1702.3509278350512,"y":846.3427422680411},{"x":1762.2628453608233,"y":1071.9684742268043}]},
                {"type":0,"label":"34","marked":false,"labels":"Dentes","pts":[{"x":1638.6148453608246,"y":856.5405154639174},{"x":1687.054268041237,"y":1100.0123505154638}]},
                {"type":0,"label":"33","marked":false,"labels":"Dentes","pts":[{"x":1563.406268041237,"y":850.1669072164947},{"x":1620.7687422680401,"y":1105.111237113402}]},
                {"type":0,"label":"32","marked":false,"labels":"Dentes","pts":[{"x":1509.8679587628865,"y":860.3646804123711},{"x":1522.6151752577314,"y":1096.1881855670101}]},
                {"type":0,"label":"31","marked":false,"labels":"Dentes","pts":[{"x":1452.5054845360821,"y":847.6174639175257},{"x":1460.1538144329893,"y":1083.440969072164}]},
                {"type":0,"label":"41","marked":false,"labels":"Dentes","pts":[{"x":1398.9671752577317,"y":845.0680206185566},{"x":1390.04412371134,"y":1073.243195876289}]},
                {"type":0,"label":"42","marked":false,"labels":"Dentes","pts":[{"x":1335.231092783505,"y":843.793298969072},{"x":1322.4838762886595,"y":1088.5398556701023}]},
                {"type":0,"label":"43","marked":false,"labels":"Dentes","pts":[{"x":1271.495010309278,"y":837.4196907216493},{"x":1228.1544742268045,"y":1092.364020618556}]},
                {"type":0,"label":"44","marked":false,"labels":"Dentes","pts":[{"x":1198.8358762886596,"y":846.3427422680411},{"x":1155.4953402061863,"y":1070.6937525773199}]},
                {"type":0,"label":"45","marked":false,"labels":"Dentes","pts":[{"x":1130.0009072164946,"y":824.672474226804},{"x":1085.385649484536,"y":1056.67181443299}]}
            ]
        },
    ]
}