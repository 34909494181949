<template>
    <div 
        @click="onClickEvent" 
        :title="hint"
        :style="btStyle"
        :class="{Button:true, raised: active, disabled: disabled}">
        <slot></slot>
        <img class="flexChild" :src="img"/>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { helper  } from '../mixins/helper';
//import { gConsts } from '../globals/gConsts';

export default {
    props: {
        img      : { type: String  },
        hint     : { type: String  },
        active   : { type: Boolean },
        round    : { type: Boolean },
        flat     : { type: Boolean },
        color    : { type: String  },
        disabled : { type: Boolean }
    }, 

    mixins: [helper],

    data () {
        return {
        }
    },

    computed: {
        ...mapState([
        ]),

        ...mapGetters([
        ]),   
        
        btStyle() {
            var colorChange = -1;

            var styleObj = {
                borderRadius : (this.round ? '50%' : 'initial'),
                background : this.color ? this.color : this.colorTheme.buttons,
                '--btRaisedChange' : 100 + (40 * colorChange) + '%',
                '--btHoverChange'  : 100 + (40 * colorChange) + '%',
                '--btActiveChange' : 100 + (40 * colorChange) + '%'
            };
            return styleObj;
        }
    },

    methods: {
        onClickEvent: function(e) {
            this.$emit('click', e);
        }
    },

    // Lifecycle hooks
    created() {
    },

    mounted() {
    },

    beforeDestroy() {
    }
}
</script>

<style scoped>
    :root {
        --btRaisedChange : 0%;
        --btHoverChange  : 0%;
        --btActiveChange : 0%;
    }

    .raised {
        box-shadow: -1px 2px 3px 0px rgba(0,0,0,0.30);
        filter: brightness(var(--btRaisedChange));
    }

    .Button{
        padding: 0px;
        margin:3px;
        cursor: pointer;
        display:inline-flex;
        transition: all .2s ease-in-out;            
        
        /*
        transition: background .2s ease-in-out, box-shadow .2s ease-in-out, filter .2s ease-in-out, transform .2s ease-in-out;    
        transition: left .2s ease-in-out, top .2s ease-in-out;    
        transition: width .2s ease-in-out, height .2s ease-in-out;    
        */
    }

    .disabled {
        pointer-events:none;
        opacity: 0.4;
    }

    .flexChild{
        margin:auto;
        width:100%;
        height:100%;
        padding:0%;
    }

    .Button:hover {
        filter: brightness(var(--btHoverChange));
    }

    .Button:active {
/*
        position: relative;
        left: -1px;
        top: 1px;
*/

        filter: brightness(var(--btActiveChange));
        box-shadow: -0.5px 0.5px 0.5px 0px rgba(146, 31, 31, 0.70);
    }

    .Button:disabled {
        opacity: 0.5;
    }


/*

Ripple effect ... 
    * o [.Button::after] seta o style de um conteudo criado em cima do div
    * o [.Button:active::after] seta o style desse mesmo conteudo qd clico no div
    * Como o o conteúdo do pseudoElem after é um radial-gradient ele é setado imediatamente
        - mas o tamanho vai crescendo com a transition
        - e a opacidade tb (vai sumindo e ficando mais claro)

.Button{
    left:100px;
    top:100px;
    position:absolute;
    border: 1px solid black;  
    cursor:pointer;  
    width: 150px;  
    height: 150px;  
    border-radius: 50%;  
    background:white;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.86);
    transition-duration: 0.6s;
    overflow:hidden;
}

.Button::after {
  content: "";
  background: radial-gradient(gray, white) center;
  background-repeat:no-repeat;
  background-size: 300% 300%;
  display: block;
  width: 100%;
  height: 100%;  
  opacity: 0;
  transition: all 1s;
}

.Button:active::after {
    background: white center;
    background-size: 1% 1%;
    background-repeat:no-repeat;
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s
}
*/

</style>
