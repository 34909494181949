// =========================================================================================
// Contantes globais

export const gConsts = {
    io : {
//        server : 'http://54.188.225.134:8530',
        server : 'https://api.radiomemory.com.br/radiomemory-interno/annotation-service',
//        server : 'http://54.188.225.134:8532',
        //server : 'https://pqttag6dpj.execute-api.us-east-1.amazonaws.com/dev/annotation-service',
        //server: 'https://3000-indigo-mosquito-4ebs6o7i.ws-us03.gitpod.io',
        reqs : {
            LOAD_PROJ : '/getImgs/',
            LOAD_IMG  : '/img/',
            SAVE_IMG  : '/saveImg/'
        }
    },

    init : {
        insertModes : {
            ADD  : 0,
            LOAD : 1 
        },

        confTypes : {
            GLOBAL : 0,
            PROJ   : 1
        }
    },

    tool : {
        types : {                 // Nao posso mudar esses nros ... sao os tipos salvos nos JSONs 
            NO_TOOL       : -1,
            PONTO         :  7,
            RETA          :  0,
            RETANGULO     :  1,
            CIRCULO       :  2,
            IMG_LABEL     :  3,
            RETANGULO_INC :  4,
            PATH          :  5,
            CURVA         :  6
        },

        states : {
            OK        : 0,
            DOUBT     : 1, 
            DIVERGENT : 2
        },

        labelPos : {
            ORIGIN : 0,
            END    : 1
        },

        colors : {
            normal : '#19ff19',
            active : 'red'
        },        
        
        handle : {
            HOVER_INDEX : 1,
            opacity : {
                NORMAL : 0.3,
                MOVING : 0.05
            }       
        },

        sizes : {
            HANDLES_PUSH_DIST : 25,
            CURVE_CREATION_MIN_DIST  : 15,
            CURVE_CREATION_MAX_DIST  : 60,
            CURVE_CREATION_MIN_ANGLE : 3
        }
    },

    img : {
        states : {            
            EMPTY     : 0,
            PARTIAL   : 1,
            CONFIRMED : 2,
            DOUBT     : 3, 
            DIVERGENT : 4,
            DISCARDED : 5
        },

        stateUpdates : {
            TOOL_ADD            : 0, 
            TOOL_DELETE         : 1,
            TOOL_UPDATE_OTHER   : 2,
            TOOL_UPDATE_STATE   : 3,
            TOOL_ADD_PTS        : 4,
            TOOL_DEL_PTS        : 5,
            IMG_RESET_DOUBT     : 6,
            IMG_RESET_DISCARDED : 7
        },

        colors : {
            EMPTY     : '#eeeeee',
            PARTIAL   : '#ffdf8f',
            DOUBT     : '#da8fff',
            DIVERGENT : '#ff8f8f',
            DISCARDED : '#178ecf',
            CONFIRMED : '#c5ecc7'
        },

        controls : {
            DOUBT   : 0,
            DISCARD : 1,
            BACK    : 2,
            SKIP    : 3,            
            CONFIRM : 4
        }        
    },

    label : {
        states : {
            UNUSED : 0,
            USED   : 1
        },

        colors : {
            DEFAULT : '#00FF00',
            UNUSED  : '#eeeeee',
            USED    : '#c5ecc7'
        },

        jumps : {
            AUTOMATIC : 0, 
            MANUAL    : 1
        },

        types : {
            NAVEGATION : 0,
            INPUT      : 1
        },
        
        navLabels : {
            LE  : 'Dentes',
            SEG : 'Segmentacao' 
        },
    },

    dentes : {
        ROI : {
            margins : {
                vert  : 0.3,
                horiz : 0.5
            }
        }
    },

    mov : {
        types : {
            WA_PAN        : 0,
            IMG_BC        : 1,
            TOOL_CREATION : 2,            
            HANDLE_EDIT   : 3,
            TOOL_DRAG     : 4,
            HANDLES_PUSH  : 5,
            HANDLES_DEL   : 6
        },
    },

    ui : {
        toolbar : {
            buttons : {
                WA_PAN        : 0,
                IMG_BC        : 1,
                IMG_ORIGIN    : 2,                
                FIT_TO_WA     : 3,
                SAVE_IMG      : 4, 
                CROSS_HAIR    : 5,
                PONTO         : 10,
                RETA          : 11,
                RETANGULO     : 12,
                CIRCULO       : 13,
                RETANGULO_INC : 14,
                PATH          : 15,
                CURVA         : 16,
                DEL_TOOL      : 17,
                IMG_LABEL     : 18
            },
        },

        workArea : {
            zoom : {
                MIN : 0.05,
                MAX : 10                
            },
        },

        colorTheme: {
            types : {
                DARK  : 0,
                LIGHT : 1
            },

            themes : {
                DARK  : 0,
                LIGHT : 1,
            },

            data : [
                { 
                    name       : 'dark',
                    type       :  0,
                    header     : '#333333',
                    panels     : '#888888',
                    workArea   : '#1E1E1E',  
                    toolbar    : '#252526',
                    buttons    : '#e9e9e9',
                    titles     : 'white'
                },

                {
                    name       : 'light',
                    type       : 1,
                    header     : '#aaaaaa',
                    panels     : '#d5d5d5', 
                    toolbar    : '#cccccc', 
                    workArea   : '#f5f5f5', 
                    buttons    : '#e9e9e9',
                    titles     : 'black'
                }
            ]
        }

    }
};