<template>
    <g id ="denteROI"
    >
        <rect id ="denteROI_moldura"
            stroke="yellow"
            stroke-width="40"
            fill="none"
            opacity="0.2"
            class="denteROI"
            v-bind="{ 
                x      : roiPos.x, 
                y      : roiPos.y,
                width  : roiPos.width,
                height : roiPos.height,
            }"
        />

        <text id ="denteROI_label"
            v-bind="{
                ref : label.label,
                x : label.x,
                y : label.y,
            }"
            class="toolLabel"
            dominant-baseline="middle" text-anchor="middle"                
        >
            <tspan
                :style="{
                    strokeWidth : 1/ui.workArea.zoom.scale,
                    fontSize    : labelInfo.font/ui.workArea.zoom.scale,
                    fontWeight  : 'bold',
                    fill        : 'yellow'
                }"
            >{{ label.dente }}</tspan>
            <tspan 
                :style="{
                    strokeWidth : 1/ui.workArea.zoom.scale,
                    fontSize : (labelInfo.font-5)/ui.workArea.zoom.scale,
                    fill     : label.labelColor
                }"
            > 
                [{{ label.label }}]
            </tspan>
        </text>
    </g>

</template>

// =========================================================================================
// =========================================================================================

<script>
import { mapState } from 'vuex';
import { gConsts  } from '../globals/gConsts';
import { utils    } from '../globals/utils';

// =========================================================================================

export default {
    data () {
        return {
            gConsts : gConsts,
            size  : 3000,
            hide : true,
            lastDente : -1,
            labelInfo : {
                dist  : 50,
                font  : 25,
                lineW : 1
            },
        }
    },

    // -----------------------------------------------------------------

    computed: {
        ...mapState([
            'controlInfo',
            'proj', 
            'ui'
        ]),

        // -----------------------------------------------------------------
        margins() {
            return {
                vert  : this.gConsts.dentes.ROI.margins.vert  * this.controlInfo.dentesROIFactor/10,
                horiz : this.gConsts.dentes.ROI.margins.horiz * this.controlInfo.dentesROIFactor/10,
            }
        },


        roiPos() {
            var ad      = this.proj.tools[this.controlInfo.activeDente],
                leMean  = this.controlInfo.leMean,
                minX = 99999,
                minY = 99999,
                maxX = 0,
                maxY = 0,
                vertMargin  = this.margins.vert,
                horizMargin = this.margins.horiz,
                rectPts = [],
                vMarginRaiz, vMarginCoroa, vMarginLado1, vMarginLado2,
                pA, pB, pA_, pB_, vAB, vPerp_vAB, 
                
                compEl, compElBBox, width, height;

            vertMargin  *= leMean;
            horizMargin *= leMean;

            switch (ad.labels) {
                case this.gConsts.label.navLabels.LE : 
                    pA = this.$Victor.fromObject(ad.pts[0]);
                    pB = this.$Victor.fromObject(ad.pts[1]);

                    vAB = pB.clone().subtract(pA).normalize();
                    vPerp_vAB = utils.vPerp_vAB(vAB);
                    
                    vMarginCoroa = vAB.clone().multiply(this.$Victor(vertMargin, vertMargin));
                    vMarginRaiz  = vMarginCoroa.clone().invert();

                    vMarginLado1 = vPerp_vAB.clone().multiply(this.$Victor(horizMargin, horizMargin));
                    vMarginLado2 = vMarginLado1.clone().invert();

                    pA_ = pA.clone().add(vMarginRaiz);
                    pB_ = pB.clone().add(vMarginCoroa);

                    rectPts.push(pA_.clone().add(vMarginLado1));            
                    rectPts.push(pA_.clone().add(vMarginLado2));
                    rectPts.push(pB_.clone().add(vMarginLado1));
                    rectPts.push(pB_.clone().add(vMarginLado2));

                    // acha o bbox do retangulo do dente
                    rectPts.forEach(pt => {
                        if (pt.x < minX) {
                            minX = pt.x;                    
                        }
                        if (pt.x > maxX) {
                            maxX = pt.x; 
                        }

                        if (pt.y < minY) {
                            minY = pt.y;                    
                        } 
                        if (pt.y > maxY) {
                            maxY = pt.y; 
                        } 
                        
                        width  = (maxX - minX);
                        height = (maxY - minY);
                    });

                    break;
                case this.gConsts.label.navLabels.SEG : 
                    compEl = utils.findToolCMP(this.$root, ad.id).$el.childNodes[0];
                    compElBBox = compEl.getBBox();

                    minX   = compElBBox.x - horizMargin/2;
                    minY   = compElBBox.y - vertMargin/2;
                    width  = compElBBox.width  + horizMargin;
                    height = compElBBox.height + vertMargin;
                    break;
            }

            return {
                x : minX,
                y : minY,
                width  : width,
                height : height
            }                
        },

        // -----------------------------------------------------------------

        label() {
            var labelObj,
                cInfo = this.controlInfo;

            labelObj = this.proj.labels[cInfo.activeLabels].list[cInfo.activeLabel];
            return {
                dente      : this.proj.tools[cInfo.activeDente].label,
                label      : labelObj.label,
                labelColor : labelObj.color,
                x          : this.roiPos.x + (this.roiPos.width/2),
                y          : this.roiPos.y - this.labelInfo.dist
            }
        },
    },

    // -----------------------------------------------------------------

    methods: {
    },

    beforeUpdate() {
        this.lastDente = this.controlInfo.activeDente;
    },

    // updated() {
    //     this.hide = false;
    // }
}
</script>

// =========================================================================================
// =========================================================================================

<style scoped>
    .denteROI {
        transition : all .2s ease-out;
    }

    .hide {
        opacity : 0;
    }

    .toolLabel {
        font: sans-serif;
        font-size: 47px;
    }
</style>