<template>
    <g>
        <path 
            v-bind="{
                id : tool.id + '-body',
                d : d
            }"
        />
    </g>
</template>

// =========================================================================================
// =========================================================================================

<script>
import { paths } from '../../mixins/paths';

// =========================================================================================

export default {
    mixins: [paths],

    // -----------------------------------------------------------------

    data () {
        return {
        }
    },

    // -----------------------------------------------------------------

    computed: {  
        d() {
            var idx, dStr,
                pts  = this.tool.pts;

            dStr = 'M ' + pts[0].x + ' ' + pts[0].y;            
            for (idx = 1; idx < pts.length; idx++) {
                dStr += ' L ' + pts[idx].x + ' ' + pts[idx].y
            }

            return this.closePath(dStr);
        },
    },

    // -----------------------------------------------------------------

    methods: {
    },
}
</script>

// =========================================================================================
// =========================================================================================

<style scoped>
</style>