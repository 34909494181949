<template>
    <div class="loginDiv">
        <div class="loginContent card-1" v-bind:class={errorBorder:msgError}>

        <h4>Login</h4>
        <form @submit="handleSubmit">
            <label for="email" >E-Mail </label>
            <div>
                <input id="email" type="email" v-model="email" required autofocus>
            </div>
            <div>
                <label for="password" >Senha</label>
                <div>
                    <input id="password" type="password" v-model="password" required>
                </div>
            </div>
            <div>
                <button type="submit" @click="handleSubmit">
                    Logar
                </button>
            </div>

            <div class="errorMsg">{{msgError}}</div>
        </form>

        </div>
    </div>
</template>
<style lang="scss" scoped>
.loginDiv{
    background: aliceblue;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 300;
}
.loginContent{
    width: 400px;
    height: 320px;
    background: white;
    margin: 0 auto;
    padding: 20px;
    margin-top: 17%;
    
}
.card-1 {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.card-1:hover {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}
.errorBorder{
     box-shadow: 0 1px 3px rgba(155, 4, 4, 0.603), 0 1px 2px rgba(209, 10, 10, 0.637);
}

section{
	background-color: rgba(0, 0, 0, 0.72);
	width:25%;
	min-height:25%;
	display:flex;
	flex-direction:column;
	/*margin-left:auto;
	margin-right:auto;*/
}
form{
	display:flex;
	flex-direction:column;
	padding: 15px; 
}
h2{
	font-family: 'Archivo Black', sans-serif;
	color:#e0dada;
	margin-left:auto;
	margin-right:auto;
}



.info.error{
	border:1px solid #a90e00;
	background-color: #ff3c41;
	color:#a90e00;
}
.info p{
	margin:auto;
	padding:5px;
}
.info.good{
	border:1px solid #416d50;
	background-color: #47cf73;
	color:#416d50;
}

.errorMsg{
    color: #be5256;
    font-weight: bold;
}

input{
	height:35px;
	padding: 5px 5px;
	margin: 10px 0px;
	background-color:#e0dada;
	border:none;
    width: 90%;
}
button{
	height:40px;
	padding: 5px 5px;
	margin: 10px 0px;
	font-weight:bold;
	background-color:#be5256;
	border:none;
	color:#e0dada;
	cursor:pointer;
	font-size:16px;
}
button:hover{
	background-color:#711f1b;
}

@keyframes shake{
	from, to{
		-webkit-transform: translate3d(0, 0, 0);
		transform:translate3d(0,0,0);
	}
	10%,30%,50%,70%,90%{
		-webkit-transform: translate3d(-10px, 0, 0);
		transform:translate3d(-10px,0,0);
	}
	20%,40%,60%,80%{
		-webkit-transform: translate3d(10px, 0, 0);
		transform:translate(10px,0,0);
	}
}

.shake{
	animation-name: shake;
	animation-duration:1s;
	/*animation-fill-mode: both;*/
}

@media screen and (max-width: 780px) {
	section{
		width:90%;
	}
}
</style>
<script>
import {gConsts} from '../globals/gConsts'
    export default {
        data(){
            return {
                email : "",
                password : "",
                msgError: ""
            }
        },
        methods : {
            handleSubmit(e){
                e.preventDefault()
                this.msgError = ''
                if (this.password.length > 0) {
                    
                    this.$axios.post(gConsts.io.server + '/login', {
                        email: this.email,
                        password: this.password
                    })
                    .then(response => {
                        if(response.data.status == 200){
                            let token = response.data.token
                            localStorage.setItem('auth', token)
                            if(this.$route.query.nextUrl != null){
                                this.$router.push(this.$route.query.nextUrl)
                            }
                        }else{
                            this.msgError = response.data.msg
                        }
                    })
                    .catch(function (error) {
                        console.error(error.response);
                    });
                }
            }
        }
    }
</script>