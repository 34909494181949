<template>
    <g
        v-show="mov.mouseInWa"
        :stroke-width="1/ui.workArea.zoom.scale"
        stroke="red"
        :stroke-dasharray="dasharrayStr"
    >
        <line
            v-bind="{
                id : 'crossHair-horizLine',
                x1 : mousePos.x - size,
                y1 : mousePos.y,
                x2 : mousePos.x + size,   
                y2 : mousePos.y,
            }" 
        />

        <line
            v-bind="{
                id : 'crossHair-vertLine',
                x1 : mousePos.x,
                y1 : mousePos.y - size,
                x2 : mousePos.x,   
                y2 : mousePos.y + size,
            }" 
        />

    </g>
</template>

// =========================================================================================
// =========================================================================================

<script>
import { mapState } from 'vuex';

// =========================================================================================

export default {
    data () {
        return {
            size : 3000
        }
    },

    computed: {
        ...mapState([
            'mov', 
            'ui'
        ]),

        dasharrayStr() {
            return 6/this.ui.workArea.zoom.scale + ' ' + 4/this.ui.workArea.zoom.scale;
        },

        mousePos() {
            return {
                x: ((this.mov.mousePos.x - this.ui.workArea.zoom.offset.x) / this.ui.workArea.zoom.scale),
                y: ((this.mov.mousePos.y - this.ui.workArea.zoom.offset.y) / this.ui.workArea.zoom.scale)                
            }
        }
    },

    methods: {
    },
}
</script>

// =========================================================================================
// =========================================================================================

<style scoped>
</style>