import { mapActions, mapState } from 'vuex';
import { utils } from '../globals/utils';
import { tools } from './tools';
import _ from 'lodash';
    
export const paths = {

    mixins: [tools],

    // =========================================================================================

    data () {
        return {
        }
    },

    // =========================================================================================

    computed: {
        ...mapState([ 
            'ui',
            'mov',
            'controlInfo'
        ]),

    },

    // =========================================================================================

    methods: {
        ...mapActions([
            'ACT_toolUpdate',
            'ACT_toolAddPts',
            'ACT_toolDelPts',
            'ACT_movUpdate'
        ]),

        // -----------------------------------------------------------------

        closePath(dStr) {
            return dStr + (this.controlInfo.closedPaths ? ' Z' : '');
        },

        // -----------------------------------------------------------------

        delPts(pM) {
            var i, pA, vMA, vMALen,
                delPts  = [],
                pts     = this.tool.pts,
                delDist = this.gConsts.tool.sizes.HANDLES_PUSH_DIST/this.ui.workArea.zoom.scale;

            for (i = 0; i < pts.length; i++) {
                pA = this.$Victor.fromObject(pts[i]);
                vMA = pA.clone().subtract(pM);
                vMALen = vMA.length();
                if (vMALen < delDist) {
                    if (delPts.length < (pts.length - 3))
                    delPts.push(i);  
                }
            }

            if (delPts.length > 0) {
                this.ACT_toolDelPts({ delPts : delPts });
            }
        },

        // -----------------------------------------------------------------

        pushPts(pM) {
            var i, pA, vMA, vMALen, vMA_, pA_, pB, 
                projM_vAB, vAB, vM_ProjM_vAB, vM_ProjM_vAB_len,
                vMALenMin = 99999,
                newPts    = {},
                pushPts   = {},
                pts       = this.tool.pts,
                pushDist  = this.gConsts.tool.sizes.HANDLES_PUSH_DIST/this.ui.workArea.zoom.scale;

            for (i = 0; i < pts.length; i++) {
                pA = this.$Victor.fromObject(pts[i]);
                vMA = pA.clone().subtract(pM);
                vMALen = vMA.length();
                vMALenMin = Math.min(vMALen, vMALenMin);
                if (vMALen < pushDist) {
                    vMA_ = vMA.clone().normalize().multiply(new this.$Victor(pushDist, pushDist));
                    pA_ = pM.clone().add(vMA_);    
                    pushPts[i] = pA_.toObject();
                }
            }

            // Se nao achou nenhum ponto dentro do circulo da ferramenta
            if (_.isEmpty(pushPts) && (vMALenMin > (pushDist * 1.2))) {
                // Procuro retas que estejam cortando o circulo
                for (i = 0; i < pts.length - 1; i++) {
                    pA  = this.$Victor.fromObject(pts[i]);
                    pB  = this.$Victor.fromObject(pts[i+1]);
                    vAB = pB.clone().subtract(pA);
                    projM_vAB = utils.projP_vAB(pM, pA, vAB);
                    vM_ProjM_vAB = projM_vAB.proj.clone().subtract(pM);
                    vM_ProjM_vAB_len = vM_ProjM_vAB.length();
                    // Se a proj de pM estiver no seg AB e dentro do circulo da ferramenta, crio o ponto
                    if ((vM_ProjM_vAB_len < pushDist) && (projM_vAB.factor > 0) && (projM_vAB.factor < 1)) {
                        newPts[i + 1] = projM_vAB.proj.toObject();  
                    }
                }   
                if (!_.isEmpty(newPts)) {
                    this.ACT_toolAddPts({ newPts : newPts });
                }
            } else {
                this.ACT_toolUpdate({ pts : pushPts });
            }
        },

        // -----------------------------------------------------------------

        mouseDown(evt, pM, toolPt) {
            var pA, pB, vAB, i, distM_vAB,
            handled   = false,             
            minDist   = 99999,
            nearestPt = -1,
            pts       = this.tool.pts,
            newPt     = {};

            this.$parent.mouseDown(evt, pM, toolPt);

            switch (evt.button) {
                case 2 : 
                    if (toolPt != -1) {
                        this.ACT_toolDelPts({ delPts : [toolPt] })
                    } else {
                        for (i = 0; i < pts.length - 1; i++) {
                            pA  = this.$Victor.fromObject(pts[i]); 
                            pB  = this.$Victor.fromObject(pts[i + 1]); 
                            vAB = pB.clone().subtract(pA);
                            distM_vAB = utils.distP_vAB(pM, pA, pB);
        
                            if ( (distM_vAB != -1    ) &&  // projM se encontra dentro do segmento de reta vAB
                                (distM_vAB < minDist) ) {  // menor distancia aa proj
                                minDist   = distM_vAB;
                                nearestPt = i;
                            }
                        }

                        newPt[nearestPt + 1] = pM;
                        this.ACT_toolAddPts({ newPts : newPt });
                    }
                    handled = true;        
                    break;
            }
            return handled;
        },

        // -----------------------------------------------------------------

        mouseMove(evt, pM) {            
            var pA, pB, vAB, vAM, dist_vBM,
                angle = -1,
                minDist = (this.gConsts.tool.sizes.CURVE_CREATION_MIN_DIST/this.ui.workArea.zoom.scale), 
                maxDist = (this.gConsts.tool.sizes.CURVE_CREATION_MAX_DIST/this.ui.workArea.zoom.scale),
                minAng  = this.gConsts.tool.sizes.CURVE_CREATION_MIN_ANGLE,
                pts     = this.tool.pts,
                newPt   = {},
                handled = false;

            this.$parent.mouseMove(evt, pM);

            switch (this.mov.type) {
                case this.gConsts.mov.types.TOOL_CREATION : 
                    if ( (this.mov.dragging) && (evt.button == 0) ) {
                        pB = this.$Victor.fromObject(pts[pts.length - 2]); 
                        dist_vBM = pM.clone().subtract(pB).length();

                        if (pts.length >= 3)  {
                            pA  = this.$Victor.fromObject(pts[pts.length - 3]);
                            vAB = pB.clone().subtract(pA);
                            vAM = pM.clone().subtract(pA);
                            angle = Math.abs(vAB.angleDeg() - vAM.angleDeg());
                        }

                        if ( ( (pts.length == 2) && (dist_vBM > minDist) ) ||
                            (dist_vBM > maxDist                         ) ||
                            ( (dist_vBM > minDist) && (angle > minAng)  ) ) {
                            // console.log('Angle: ' + angle);
                            // console.log('pA: ' + pA);
                            // console.log('pB: ' + pB);
                            // console.log('pA: ' + pM);

                            newPt[pts.length] = pM;
                            this.ACT_toolAddPts({ newPts : newPt })
                            this.ACT_movUpdate({ activeToolPt : pts.length - 1 });

                            if (angle > 10) {
                                console.log('deu biziu :(')
                            }
                        }
                        /* handled vai continuar false pq o mouseMove do medidor ainda tem que 
                           aplicar o delta do movimento no novo ponto */
                    }
                    break;
                
                case this.gConsts.mov.types.HANDLES_PUSH : 
                case this.gConsts.mov.types.HANDLES_DEL  : 
                    switch (this.mov.type) {
                        case this.gConsts.mov.types.HANDLES_PUSH : this.pushPts(pM); break;
                        case this.gConsts.mov.types.HANDLES_DEL  : this.delPts(pM);  break;
                    }
                    handled = true;
                    break;
            }

            return handled;
        },

        // -----------------------------------------------------------------

    }
}