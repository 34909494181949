<template>
    <g>
        <path 
            v-bind="{
                id : tool.id + '-body',
                d : d
            }"
        />
    </g>
</template>

// =========================================================================================
// =========================================================================================

<script>
import { paths } from '../../mixins/paths';

// =========================================================================================

export default {
    mixins: [paths],

    // -----------------------------------------------------------------

    data () {
        return {
        }
    },

    // -----------------------------------------------------------------

    computed: {   
        /* 
            ref: https://stackoverflow.com/questions/62855310/converting-a-list-of-points-to-an-svg-cubic-piecewise-bezier-curve
            ref2 (parece interessante se nao for mto parecido) : https://stackoverflow.com/questions/15691499/how-do-i-draw-a-closed-curve-over-a-set-of-points
        */

        d() {
            var idx, dStr, dx, dy, n,
                t    = 1/5,
                cPts = [],
                pts  = this.tool.pts;

            dStr = 'M' + pts[0].x + ' ' + pts[0].y;

            if (pts.length > 2) {
                for (idx = 1; idx < (pts.length - 1); idx++) {
                    dx = pts[idx - 1].x - pts[idx + 1].x;
                    dy = pts[idx - 1].y - pts[idx + 1].y;

                    cPts[idx] = {
                        cPt_pre : {
                            x: pts[idx].x - dx * t,
                            y: pts[idx].y - dy * t,
                        },
                        cPt_pos : {
                            x: pts[idx].x + dx * t,
                            y: pts[idx].y + dy * t,
                        },
                    };
                }

                dStr += ' Q' + cPts[1]['cPt_pos'].x + ' ' + cPts[1]['cPt_pos'].y + ' ' + pts[1].x + ' ' + pts[1].y;  // 1a curva quadratica
                for (idx = 1; idx < (pts.length - 2); idx++) {
                    dStr += ' C' + cPts[idx    ]['cPt_pre'].x + ' ' + cPts[idx    ]['cPt_pre'].y + 
                             ' ' + cPts[idx + 1]['cPt_pos'].x + ' ' + cPts[idx + 1]['cPt_pos'].y + 
                             ' ' + pts[idx + 1].x + ' ' + pts[idx + 1].y;
                }
                n = pts.length - 1                
                dStr += ' Q' + cPts[n - 1]['cPt_pre'].x + ' ' + cPts[n - 1]['cPt_pre'].y + ' ' + pts[n].x + ' ' + pts[n].y;  // ultima curva quadratica

            } else {
                dStr += ' L' + pts[1].x + ' ' + pts[1].y;
            }

            return this.closePath(dStr);
        },
    },

    // -----------------------------------------------------------------

    methods: {
    }
}
</script>

// =========================================================================================
// =========================================================================================

<style scoped>
</style>