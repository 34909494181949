<template>
    <div>
        <slot name="mainImg"></slot>        
        <slot name="tools"></slot>        
    </div>
</template>

// =========================================================================================
// =========================================================================================

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { helper  } from '../mixins/helper';
import { gConsts } from '../globals/gConsts';
import { utils   } from '../globals/utils';
import { evtBus  } from '../main';
import { undoRedoHistory } from '../globals/undoRedo';

// =========================================================================================

export default {
    components: {
    },

    // =========================================================================================

    mixins: [helper],

    // =========================================================================================

    data () {
        return {
            htmlElems : null,  // parentElem, workAreaZoom e workArea ... init no mounted()
            gConsts : gConsts,
            undoRedoHistory : undoRedoHistory,
            utils : utils,
            toolCMPs : {},  
            timeouts : {
                adTimeoutID : -1,
                adTimeout   : 100
            }          
        }
    },

    // =========================================================================================

    computed: {
        ...mapState([
            'proj',
            'imgs',
            'mov',
            'ui',
            'controlInfo'
        ]),

        // -----------------------------------------------------------------
        ...mapGetters([
            'GET_leTools',
            'GET_segTools'
        ]),
    },

    // =========================================================================================

    methods: {
        ...mapActions([
            'ACT_cInfoUpdate',
            'ACT_movUpdate',
            'ACT_workAreaZoom',
            'ACT_workAreaImgBC',
            'ACT_labelNav',
            'ACT_toolAdd',
            'ACT_toolUpdate',
            'ACT_toolAddPts',
            'ACT_toolDelete',
            'ACT_activeKeysUpdate'
        ]),

        // -----------------------------------------------------------------

        waMousePos: function(evt) {
            var waMousePos, waScaledMousePos, waUnscaledMousePos,
                zoom   = this.ui.workArea.zoom, 
                waBBox = this.htmlElems.workArea.getBoundingClientRect();

            waMousePos = {
                x : (evt.clientX - waBBox.x),
                y : (evt.clientY - waBBox.y)
            }
            waScaledMousePos = {
                x : waMousePos.x - zoom.offset.x,
                y : waMousePos.y - zoom.offset.y  
            }
            waUnscaledMousePos = {
                x : waScaledMousePos.x / zoom.scale,
                y : waScaledMousePos.y / zoom.scale
            }

            return {
                normal   : waMousePos,
                scaled   : waScaledMousePos,
                unscaled : waUnscaledMousePos
            }
        },

        // -----------------------------------------------------------------

        keyboardEvents: function(evt) {
            var newState,
                activeTool = this.controlInfo.activeTool,
                labelInc = 0;
//                movStep = 50, 
//                delta = { x: 0, y: 0 };
           
            switch (evt.type) {
                case 'keydown' : 
                    switch (evt.key) {
                        case 'ArrowDown'  : 
                        case 'ArrowLeft'  :
                        case 'ArrowRight' :
                        case 'ArrowUp'    :
                            switch (evt.key) {
                                case 'ArrowDown'  : 
                                case 'ArrowRight' : labelInc = 1;  break;
                                case 'ArrowLeft'  : 
                                case 'ArrowUp'    : labelInc = -1; break;
                            }
                            this.ACT_labelNav( {inc : labelInc} )
                            break;
                        
                        case 'Delete' : this.ACT_toolDelete(); break;

                        case 'p' :
                            if (!evt.repeat) {
                                this.ACT_activeKeysUpdate({ p : true });
                            } 
                            break;

                        case 'm' : this.ACT_toolUpdate( { mark : true } ); break;

                        case 'z' :
                        case 'y' :
                            if (evt.ctrlKey) {
                                switch (evt.key) {
                                    case 'z' : this.undoRedoHistory.undo(); break;
                                    case 'y' : this.undoRedoHistory.redo(); break;
                                }                                
                            }
                            break;

                        case ' ' :
                            if ( (activeTool                                      ) && 
                                 (this.mov.type != gConsts.mov.types.TOOL_CREATION) &&
                                 (this.proj.labels[this.controlInfo.activeLabels].type == this.gConsts.label.types.INPUT) ) {
                                switch (activeTool.state) {
                                    case this.gConsts.tool.states.OK        : newState = this.gConsts.tool.states.DOUBT; break;

                                    case this.gConsts.tool.states.DOUBT     :
                                    case this.gConsts.tool.states.DIVERGENT : newState = this.gConsts.tool.states.OK;    break;
                                }
                                this.ACT_toolUpdate({ state : newState });
                                evt.stopPropagation();   
                                evt.preventDefault();
                            }
                            break;
                    }
                    break;

                case 'keyup' : 
                    switch (evt.key) {
                        case 'p' : this.ACT_activeKeysUpdate({ p : false }); break;
                    }
                    break;
            } 

//            evt.stopPropagation();
//            evt.preventDefault();

        },

        // -----------------------------------------------------------------
        
        mouseEvents: function(evt) {
            var movType, re, reMatch, toolId, tool, targetType, numKeys,
                payload = {},
                targetId = evt.target.id,
                toolInfo = {
                    type   : -1,
                    tool   : null,
                    toolPt : -1 
                };

            // Se o evento do mouse estiver ocorrendo sobre a workArea
            if (this.isElemOrChild(evt.target, this.htmlElems.workArea)) {
                if (evt.type == 'mousedown') {
                    /* Se estamos criando uma tool antes do mouseDown que acabou de acontecer, quer dizer que o 
                       usuario clicou e soltou o mouse na mesma posição. 
                         O termino da criação fica adiado ateh o proximo mouseUp (se houver movimento) e portanto
                       cancelo o mouseDown corrente pois iniciaria a criação de outra tool. */
                    if (this.mov.type == gConsts.mov.types.TOOL_CREATION) {
                        this.ACT_movUpdate({ dragging : true });
                        return;
                    }

                    // A tecla CTRL tem prioridade e permite o PAN da imagem com o mouse mesmo com uma tool selecionada
                    if (evt.ctrlKey) {
                        movType = this.gConsts.mov.types.WA_PAN;

                    } else if (evt.shiftKey) {
                        movType = this.gConsts.mov.types.IMG_BC;

                    } else {                 
                        // Se clicou sobre uma tool (handles ou corpo)
                        if ((targetId.search('tool_') != -1) && (!evt.altKey)) {
                            re = /(tool_(\w+)\[(\d+)\])(?:-((\w+)(?:\[(\d+)\])?))?/;
                            reMatch = re.exec(targetId); // ex: tool_reta[12]-h[1] => 1: tool_reta[12], 2: reta, 3: 12, 4: h[1], 5: h, 6: 1

                            toolId = reMatch[1];
                            tool = this.utils.findTool(this.proj.tools, toolId);

                            // Clique do botao direito serve para mudar o state da tool
                            // if (evt.button == 2) {
                            //     if (tool && (this.mov.type == -1)) {
                            //         switch (tool.state) {
                            //             case this.gConsts.tool.states.OK        : newState = this.gConsts.tool.states.DOUBT; break;

                            //             case this.gConsts.tool.states.DOUBT     :
                            //             case this.gConsts.tool.states.DIVERGENT : newState = this.gConsts.tool.states.OK;    break;
                            //         }

                            //         this.ACT_toolUpdate({ 
                            //             tool  : tool, 
                            //             state : newState 
                            //         })
                            //     }
                            //     return;
                            // }

                            toolInfo.tool = tool;

                            targetType = reMatch[5];
                            switch (targetType) {
                                case 'h'    :                             
                                    movType = this.gConsts.mov.types.HANDLE_EDIT; 
                                    toolInfo.toolPt = parseInt(reMatch[6], 10); 
                                    break;
                                case 'body' : 
                                    switch (evt.button) {
                                        case 0 : movType = this.gConsts.mov.types.TOOL_DRAG  ; break;
                                        case 2 : movType = this.gConsts.mov.types.HANDLE_EDIT; break;
                                    }
                                    break;                                    
                            }

                        } else {
                            switch (evt.button) {
                                case 0 :                                
                                    // Se clicou sobre a imagem e temos uma tool selecionada, partimos para criacao desta tool
                                    if (this.ui.toolbar.activeToolType != this.gConsts.tool.types.NO_TOOL) {
                                        movType = this.gConsts.mov.types.TOOL_CREATION;
                                        toolInfo.type = this.ui.toolbar.activeToolType;

                                    // Se clicou sobre a imagem sem tool selecionada, temos que olhar qual funcao esta ativa
                                    } else {
                                        switch (this.ui.toolbar.activeButton) {
                                            case gConsts.ui.toolbar.buttons.WA_PAN : movType = this.gConsts.mov.types.WA_PAN; break;
                                            case gConsts.ui.toolbar.buttons.IMG_BC : movType = this.gConsts.mov.types.IMG_BC; break;
                                        }                                    
                                    }
                                    break;

                                case 1 : 
                                case 2 : 
                                    toolInfo.tool = this.controlInfo.activeTool;
                                    switch (evt.button) {
                                        case 1 : movType = this.gConsts.mov.types.HANDLES_DEL ; break;
                                        case 2 : movType = this.gConsts.mov.types.HANDLES_PUSH; break;
                                    }
                                    break;
                            }
                        }
                    }

                // Se nao for mouseDown, apenas continuo o movimento ativo (se algum)
                } else {
                    movType = this.mov.type;                    
                }

                switch (evt.type) {
                    case 'dblclick'  : 
                        if (movType != this.gConsts.mov.types.TOOL_CREATION) {
                            this.fitToWorkArea();                             
                        }
                        break;                            
                    case 'mousedown' : payload = this.mouseDown(evt, movType, toolInfo); break;                         
                    case 'mousemove' : payload = this.mouseMove(evt);                    break;
                    case 'mouseup'   : payload = this.mouseUp(evt);                      break;
                }                
                payload['mouseInWa'] = true;

                evt.stopPropagation();   
                evt.preventDefault();

            // O evento do mouse estah fora da workArea
            } else {
                payload['mouseInWa'] = false;
            }

            /* Se o update for somente do [mouseInWa], soh dispara se tiver mudado ou estiver dentro da 
               workArea (o crossHairCMP precisa desse update) */
            numKeys = Object.keys(payload).length;
            if ( (numKeys > 1)      || 
                 this.mov.mouseInWa ||
                 ( (numKeys == 1                             ) && 
                   this.utils.objHasProp(payload, 'mouseInWa') && 
                   (this.mov.mouseInWa != payload.mouseInWa  ) &&
                   this.ui.toolbar.crossHairActive           ) ) {
                
                    this.ACT_movUpdate(payload);
            }
        },

        // -----------------------------------------------------------------

        mouseDown: function(evt, movType, toolInfo) {
            var startPos, pM, handled,
                pts = [],
                waMousePos = this.waMousePos(evt);


            switch(movType) {
                case this.gConsts.mov.types.TOOL_CREATION :
                    if (!this.mov.active) { 
                        startPos = waMousePos.unscaled;

                        pts.push(startPos);
                        if (utils.toolInfo(toolInfo.type).numPts != 1) {
                            pts.push({ x : startPos.x, y : startPos.y });
                            toolInfo.toolPt = 1;
                        } else {
                            toolInfo.toolPt = 0;
                        }

                        this.ACT_toolAdd({
                            mode : gConsts.init.insertModes.ADD,
                            type : toolInfo.type,
                            pts  : pts
                        })
                        toolInfo.tool = this.controlInfo.activeTool;
                    }
                    break;

                case this.gConsts.mov.types.TOOL_DRAG   :
                case this.gConsts.mov.types.HANDLE_EDIT :
                    pM = this.$Victor.fromObject(waMousePos.unscaled);
                    handled = this.toolCMPs[toolInfo.tool.id].mouseDown(evt, pM, toolInfo.toolPt);
                    break;

                case this.gConsts.mov.types.WA_PAN    :                     
                    break;
            }


            // Tô retornando o default/ativo qdo nao eh creation, mas o ideal seria nao gerar evento ...
//            console.log('mouseDown: dragging');
            return {
                activeTool    : toolInfo.tool, 
                activeToolPt  : toolInfo.toolPt,   
                type          : movType,
                active        : true,
                dragging      : true,
                mousePos      : waMousePos.normal
            }
        },

        // -----------------------------------------------------------------

        mouseMove: function(evt) {
            var scaledDelta, delta, pM, 
                tool,
                moved = false,
                handled = false,
                waMousePos = this.waMousePos(evt);

            pM = this.$Victor.fromObject(waMousePos.unscaled);

            clearTimeout(this.timeouts.adTimeoutID);
            this.timeouts.adTimeoutID = setTimeout(() => this.updateActiveDente(pM, evt), this.timeouts.adTimeout);

            if (this.mov.active) {
                tool = this.mov.activeTool;
                if (tool != null) {
                    handled = this.toolCMPs[tool.id].mouseMove(evt, pM);
                }

                if (!handled) {
                    scaledDelta = {
                        x : (waMousePos.normal.x - this.mov.mousePos.x), // / this.ui.workArea.zoom.scale,
                        y : (waMousePos.normal.y - this.mov.mousePos.y)  // / this.ui.workArea.zoom.scale
                    };    

                    delta = {
                        x : scaledDelta.x / this.ui.workArea.zoom.scale,
                        y : scaledDelta.y / this.ui.workArea.zoom.scale
                    };                

                    switch (this.mov.type) {
                        case this.gConsts.mov.types.TOOL_CREATION :
                        case this.gConsts.mov.types.HANDLE_EDIT   :
                        case this.gConsts.mov.types.TOOL_DRAG     : 
                        case this.gConsts.mov.types.HANDLES_PUSH  :
                        case this.gConsts.mov.types.HANDLES_DEL   : this.ACT_toolUpdate({ delta : delta }); break;
                        
                        case this.gConsts.mov.types.WA_PAN : this.ACT_workAreaZoom( { delta : scaledDelta }); break;
                        case this.gConsts.mov.types.IMG_BC : this.ACT_workAreaImgBC({ delta : scaledDelta }); break;
                    }
                }

                moved = true;
            }

            return {
                moved    : this.mov.moved || moved,                    
                mousePos : waMousePos.normal
            }
        },

        // -----------------------------------------------------------------

        mouseUp: function(evt) {
            var numPts, pM, waMousePos, 
                handled = false,
                payload             = {}, 
                isToolCreation      = (this.mov.type == this.gConsts.mov.types.TOOL_CREATION),
                minSizeEnsured      = false,
                totalToolPtsReached = false,
                isPathTool          = false,
                isLastToolPoint     = false,
                tool                = this.mov.activeTool,
                toolPt              = this.mov.activeToolPt;

            if (this.mov.active) {
                if (isToolCreation) {
                    numPts = utils.toolInfo(tool.type).numPts;
                    
                    isPathTool          = (utils.isInSet(tool.type, [gConsts.tool.types.PATH, gConsts.tool.types.CURVA]));
                    totalToolPtsReached = (numPts == tool.pts.length);
                    isLastToolPoint     = totalToolPtsReached || (isPathTool && (evt.button == 2));
                    minSizeEnsured      = (numPts == 1) 
                                          || (this.$Victor.fromObject(tool.pts[toolPt]).distance(this.$Victor.fromObject(tool.pts[toolPt-1])) > (10/this.ui.workArea.zoom.scale))
                } else {
                    if ((tool != null) && (!this.mov.moved)) {
                        waMousePos = this.waMousePos(evt);
                        pM = this.$Victor.fromObject(waMousePos.unscaled);
                        handled = this.toolCMPs[tool.id].mouseUp(evt, pM);
                    }
                }

                if (!isToolCreation || (isLastToolPoint && (minSizeEnsured || isPathTool))) {
                    payload = {
                        activeTool   : null,
                        activeToolPt : -1,
                        type         : -1,
                        active       : false,
                        moved        : false,
                        mousePos : {
                            x : 0,
                            y : 0
                        } 
                    }
                } else if ((!totalToolPtsReached || isPathTool) && minSizeEnsured) {
                    this.ACT_toolAddPts({});

                    payload = {
                        activeToolPt : tool.pts.length - 1,
                        moved        : false,
                    }
                }
            }

//            console.log('mouseUp: not dragging');
            payload['dragging'] = false;
            return payload;
        },

        // -----------------------------------------------------------------

        updateActiveDente(pM, evt) {
            var toolCMP, svgPath, svgPoint, 
                dentes = [],

                pA, pB, pB_novo, pB_active,
                distG, distM, distP, distNovo, distActive,
                ad, arcadasOpostas, duration,
                
                activeDente = this.controlInfo.activeDente,
                novoDente = -1, 
                distMin = 99999,
                start = Date.now();

            // Se estamos um uma layer que nao eh de navegacao (e o alt de lock de natGeo nao estiver ativo)
            if ((this.proj.labels[this.controlInfo.activeLabels].type !== gConsts.label.types.NAVEGATION) && (!evt.altKey)) {

                /* Se houver uma layer SEG disponivel, percorre todos e verifica se o mouse estah dentro de 
                   algum deles exclusivamente */
                if ( (utils.objHasProp(this.proj.labels, gConsts.label.navLabels.SEG)                     ) && 
                     (this.proj.labels[gConsts.label.navLabels.SEG].type == gConsts.label.types.NAVEGATION) ) {
                    this.GET_segTools.forEach(tool => {
                        toolCMP  = this.toolCMPs[tool.id];
                        svgPath  = toolCMP.$el.childNodes[0];
                        svgPoint = svgPath.ownerSVGElement.createSVGPoint()
                        svgPoint.x = pM.x;
                        svgPoint.y = pM.y;
                        if (svgPath.isPointInFill(svgPoint)) {
                            dentes.push(tool);
//                            console.log('Mouse in SEG: ' + tool.id);
                        }
                    }, this);

                    if (dentes.length == 1) {
                        novoDente = this.proj.tools.indexOf(dentes[0]);
                    }
                }

                // Se nao achou o dente pela layer SEG (que tem precedencia)
                if (novoDente != -1) {
                    activeDente = novoDente;
                } else if ( (utils.objHasProp(this.proj.labels, gConsts.label.navLabels.LE)                     ) && 
                            (this.proj.labels[gConsts.label.navLabels.LE].type == gConsts.label.types.NAVEGATION) ) {
                    // percorre todos os dentes da layer LE e acha o melhor candidato a se tornar o proximo activeDente
                    this.GET_leTools.forEach(tool => {
                        pA = this.$Victor.fromObject(tool.pts[0]);
                        pB = this.$Victor.fromObject(tool.pts[1]);
                        if (Math.min(pM.distance(pA), pM.distance(pB)) < this.controlInfo.leMean) {  // soh considero o dente como candidato se ele estiver perto do mouse
                            distNovo = utils.distP_vAB(pM, pA, pB);
                            if (distNovo < distMin) {
                                distMin   = distNovo;
                                novoDente = this.proj.tools.indexOf(tool);                                
                                pB_novo   = pB;
//                                console.log('Mouse near LE [dist ' + distMin + ']: ' + tool.id);
                            }
                        }
                    }, this);

                    distG = this.controlInfo.leMean/2;   // Dist max que distMin pode ter para ser valida (acima disso a moldura eh desativada)
                    distM = this.controlInfo.leMean/4;   // Dist minima que pM deve estar de activeDente para que este perca o foco para um novoDente (se este estiver na arcada contraria)
                    distP = this.controlInfo.leMean/15;  // Dist minima que pM tem que estar de novoDente para que este seja considerado (se jah houver um activeDente)
                    distNovo = distMin;

                    /*
                        if (pM dentro do raio distG de novoDente)
                            if (nao tem activeDente)
                                activeDente = novoDente
                            else
                                if ( (novoDente nao eh activeDente) && (pM dentro do raio distP de novoDente) )
                                    if (activeDente e novoDente estao em arcadasOpostas)
                                        if (pM fora do raio distM de activeDente)
                                            activeDente = novoDente
                                    else
                                        activeDente = novoDente
                        else
                            activeDente = -1 
                    */
                    if (distNovo < distG) {                                                               
                        if (activeDente != -1) {                                                                                  
                            if ( (novoDente != activeDente) && (distNovo < distP) ) {
                                ad = this.proj.tools[this.controlInfo.activeDente];
                                pB_active = this.$Victor.fromObject(ad.pts[1]);
                                arcadasOpostas = (pB_active.distance(pB_novo) > this.controlInfo.leMean);  // distancia das raizes maior que leMean
                                if (arcadasOpostas) {
                                    distActive = utils.distP_vAB(pM, this.$Victor.fromObject(ad.pts[0]), 
                                                                     this.$Victor.fromObject(ad.pts[1]));
                                    if (distActive > distM) {
                                        activeDente = novoDente;
                                    }
                                } else {
                                    activeDente = novoDente;
                                }
                            }
                        } else {
                            activeDente = novoDente;                                                      
                        }
                    } else {                              
                        activeDente = -1;                 
                    }
                }

                if (activeDente != this.controlInfo.activeDente) {
                    this.ACT_cInfoUpdate({ activeDente : activeDente });
                }                
            }

            duration = Date.now() - start;
//            console.log('updateActiveDente duration (ms): ' + duration);
        },

        // -----------------------------------------------------------------

        zoom : function(evt) {            
            if (!this.isElemOrChild(evt.target, this.htmlElems.workArea)) { return; }

            var zFactor, delta, newScale,
                zoom = this.ui.workArea.zoom,
                waMousePos = this.waMousePos(evt);

            // vou aumentar/diminuir o zoom de um fator de 0.25 pra cada passo do mouseWheel
            zFactor = 1 + (Math.abs(evt.deltaY)/400);  // zoomIn
            if (evt.deltaY > 0) {  // zoomOut
                zFactor = 1/zFactor;
            }  

            newScale = zoom.scale * zFactor;  // calcula o novo scale

            // Limita o zoomIn e zoomOut máximos
            if ( (newScale > this.gConsts.ui.workArea.zoom.MIN) && (newScale < this.gConsts.ui.workArea.zoom.MAX) ) {
                delta = {
                    x : (waMousePos.normal.x - (waMousePos.scaled.x * zFactor)) - zoom.offset.x,
                    y : (waMousePos.normal.y - (waMousePos.scaled.y * zFactor)) - zoom.offset.y
                }

                this.ACT_workAreaZoom({ 
                    scale : newScale,
                    delta : delta,
                });            
            }

            evt.stopPropagation();
            evt.preventDefault();            
        },

        // -----------------------------------------------------------------

        fitToWorkArea: function() {
            var fitBBox, zoom, delta, waRatio, fitRatio,
                fitTargetElem = this.htmlElems.mainImg,
                waBBox   = this.htmlElems.workArea.getBoundingClientRect(),
                wazBBox  = this.htmlElems.workAreaZoom.getBoundingClientRect();

            fitBBox = fitTargetElem.getBoundingClientRect();
            
            waRatio   = waBBox.width / waBBox.height;
            fitRatio = fitBBox.width / fitBBox.height;

            if(fitRatio > waRatio) {
                zoom = waBBox.width / fitBBox.width;
            } else {
                zoom = waBBox.height / fitBBox.height;
            }
            zoom *= 0.97;

            /* Incremento necessarios nos offsets para o correto posicionamento da pagina scaled no workArea
               OBS: temos que levar em consideracao que o transformOrigin do scale é o [0,0] do waz e por isso
                    a posicao pageBBox.[x,y] nao se mantem depois do scale e por isso deve ser calculada */
            delta = {
                x : (waBBox.width/2) - (fitBBox.width*zoom/2)                   // posicao left que a pagina fitted tem que ter
                  - (((fitBBox.x - wazBBox.x)*zoom) + (wazBBox.x - waBBox.x)),  // posicao da pagina (ref aa wa) jah levando o scale em consideracao

                y : (waBBox.height/2) - (fitBBox.height*zoom/2)
                  - (((fitBBox.y - wazBBox.y)*zoom) + (wazBBox.y - waBBox.y))
            };

            this.ACT_workAreaZoom({ 
                scale : this.ui.workArea.zoom.scale * zoom, 
                delta : delta
            });
        },
    },


    // =========================================================================================
    // Lifecycle hooks

    created() {
        evtBus.$on('evt_toolCreation', (toolId, toolCMP) => {
            this.toolCMPs[toolId] = toolCMP;
        });
    },

    // -----------------------------------------------------------------

    mounted() {
        var divDPI    = document.getElementById('divDPI'),
            screenDPI = divDPI.offsetWidth,
            pxPerMm = screenDPI / 25.4;   
        
        divDPI.style.display = 'none';
        this.ACT_cInfoUpdate({ pxPerMm : pxPerMm });
            
        this.htmlElems = {
            parentElem   : this.$el.parentNode,
            workAreaZoom : document.getElementById('div_workAreaZoom'),
            workArea     : document.getElementById('div_workArea'),
            mainImg      : document.getElementById('mainImg')
        }

        document.documentElement.addEventListener('mousedown', this.mouseEvents);
        document.documentElement.addEventListener('mousemove', this.mouseEvents);
        document.documentElement.addEventListener('mouseup'  , this.mouseEvents);
        document.documentElement.addEventListener('dblclick' , this.mouseEvents);
        document.documentElement.addEventListener('keydown'  , this.keyboardEvents);
        document.documentElement.addEventListener('keyup'    , this.keyboardEvents);
        document.documentElement.addEventListener('wheel'    , this.zoom, {capture : false, passive : false});
    },

    // -----------------------------------------------------------------

    beforeDestroy() {
        document.documentElement.removeEventListener('mousedown', this.mouseEvents);
        document.documentElement.removeEventListener('mousemove', this.mouseEvents);
        document.documentElement.removeEventListener('mouseup'  , this.mouseEvents);
        document.documentElement.removeEventListener('dblclick' , this.mouseEvents);
        document.documentElement.removeEventListener('keydown'  , this.keyboardEvents);
        document.documentElement.removeEventListener('keyup'    , this.keyboardEvents);
        document.documentElement.removeEventListener('wheel'    , this.zoom);
    }
}
</script>

// =========================================================================================
// =========================================================================================

<style scoped>
</style>