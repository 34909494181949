import { mapState } from 'vuex';
import { gConsts } from "../globals/gConsts";

export const helper = {
    computed: {
        ...mapState([ 
            'ui' 
        ]),

        colorTheme() {
            return gConsts.ui.colorTheme.data[this.ui.colorTheme];
        }
    },

    // =========================================================================================

    methods: {
        isElemOrChild: function(elem, parent) {
            return ((elem == parent) || this.isChildNode(elem, parent));
        },
        
        // -----------------------------------------------------------------
        
        isChildNode: function(elem, parent) {
            var isChild  = false;
            var currParent = elem;
            
            if (elem == null) {
                return false;
            }
            
            currParent = elem.parentNode;
            while (currParent != null) {
                if (currParent == parent) {
                    isChild = true;
                    break;
                }
                currParent = currParent.parentNode;
            }
        
            return isChild;
        }
    }
}