<template>
    <g>
        <rect 
            v-bind="{ 
                id     : tool.id + '-body',
                x      : Math.min(tool.pts[0].x, tool.pts[1].x), 
                y      : Math.min(tool.pts[0].y, tool.pts[1].y),
                width  : Math.abs(tool.pts[1].x - tool.pts[0].x),
                height : Math.abs(tool.pts[1].y - tool.pts[0].y)
            }"
        />
    </g>
</template>

// =========================================================================================
// =========================================================================================

<script>
import { tools } from '../../mixins/tools';

// =========================================================================================

export default {
    mixins: [tools],

    data () {
        return {
        }
    },

    computed: {
    },

    methods: {
    },
}
</script>

// =========================================================================================
// =========================================================================================

<style scoped>
</style>