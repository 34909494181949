var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('g',{attrs:{"id":"denteROI"}},[_c('rect',_vm._b({staticClass:"denteROI",attrs:{"id":"denteROI_moldura","stroke":"yellow","stroke-width":"40","fill":"none","opacity":"0.2"}},'rect',{ 
            x      : _vm.roiPos.x, 
            y      : _vm.roiPos.y,
            width  : _vm.roiPos.width,
            height : _vm.roiPos.height,
        },false)),_c('text',_vm._b({staticClass:"toolLabel",attrs:{"id":"denteROI_label","dominant-baseline":"middle","text-anchor":"middle"}},'text',{
            ref : _vm.label.label,
            x : _vm.label.x,
            y : _vm.label.y,
        },false),[_c('tspan',{style:({
                strokeWidth : 1/_vm.ui.workArea.zoom.scale,
                fontSize    : _vm.labelInfo.font/_vm.ui.workArea.zoom.scale,
                fontWeight  : 'bold',
                fill        : 'yellow'
            })},[_vm._v(_vm._s(_vm.label.dente))]),_c('tspan',{style:({
                strokeWidth : 1/_vm.ui.workArea.zoom.scale,
                fontSize : (_vm.labelInfo.font-5)/_vm.ui.workArea.zoom.scale,
                fill     : _vm.label.labelColor
            })},[_vm._v(" ["+_vm._s(_vm.label.label)+"] ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }