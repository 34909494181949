import _ from 'lodash';

// =========================================================================================
// Undo e redo

class UndoRedoHistory {
    store;
    history = [];
    currentIndex = -1;
  
    reset() {
        this.history.length = 0;  // clear all states
        this.currentIndex   = -1;
        this.addState(_.cloneDeep(this.store.state), 'ResetState');  // add first state (chamado depois que carrego a tudo, inclusive a 1a imagem, ou qdo mudo de imagem)
    }
  
    addState(state) {
        // may be we have to remove redo steps
        if (this.currentIndex + 1 < this.history.length) {
            this.history.splice(this.currentIndex + 1);
        }
        this.history.push(state);
        this.currentIndex++;
    }
  
    undo() {
        if (this.currentIndex < 1) { return }
    
        const prevState = this.history[this.currentIndex - 1];
        // take a copy of the history state
        // because it would be changed during store mutations
        // what would corrupt the undo-redo-history
        // (same on redo)
        this.store.replaceState(_.cloneDeep(prevState));
        this.currentIndex--;
    }
  
    redo() {
        if (this.currentIndex >= (this.history.length - 1)) { return }

        const nextState = this.history[this.currentIndex + 1];
        this.store.replaceState(_.cloneDeep(nextState));
        this.currentIndex++;
    }
}
  
export const undoRedoHistory = new UndoRedoHistory();
